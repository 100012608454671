import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateWebhookDto } from '@escapenavigator/types/dist/webhook/create-webhook.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const WebhookModal: React.FC<Props> = ({ close, cb, t }) => {
    const { webhooksApi } = useApi();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: webhooksApi.create,
        saveCallback: cb,
        close,
    });

    return (
        <RestForm
            title="Webhook"
            initialValues={ serializeRecord(CreateWebhookDto, {}) }
            validate={ validateByDto(t) }
            softError={ softError }
            save={ save }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, errors, touched, handleChange,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Input
                        label="Url"
                        value={ values.url }
                        error={ touched.url && errors.url }
                        onChange={ handleChange('url') }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
