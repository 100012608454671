/* eslint-disable complexity */
import React from 'react';
import {
    Button, Flex, showError, ToastPlate,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderCancelReasonEnum } from '@escapenavigator/types/dist/order/enum/order-cancel-reson.enum';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { useApi } from 'src/providers/api/context';

type Props = {
    order: OrderRO;
    successCalback?: () => void;
    close: () => void;
};

export const NeedConfirmAlert: React.FC<Props> = ({ successCalback, close, order }) => {
    const { orders } = useApi();

    const { confirmFetch, confirmLoading } = useApiMethod({
        api: orders.confirm,
        successCallback: () => {
            if (successCalback) successCalback();

            close();
        },
        errorCallback: (err) => showError(err.message),
    });

    const { cancelFetch, cancelLoading } = useApiMethod({
        api: orders.cancel,
        successCallback: () => {
            if (successCalback) successCalback();

            close();
        },
        errorCallback: (err) => showError(err.message),
    });

    return (
        <ToastPlate title="Требуется подтвеждение" view="attention">
            Клиент ожидает подтверждения данного бронирования
            <br />
            <br />
            <Flex justify="start" gap="md">
                <Button
                    size="xs"
                    loading={ confirmLoading }
                    onClick={ () => confirmFetch({ id: order.id }) }
                    view="outlined"
                >
                    Подтвердить
                </Button>
                <Button
                    size="xs"
                    loading={ cancelLoading }
                    onClick={ () =>
                        cancelFetch({
                            id: order.id,
                            data: {
                                cancelationType: OrderCancelReasonEnum.QUESTROOM,
                                cancelationReason: 'Order not confirmed',
                                returnCertificates: true,
                            },
                        }) }
                    view="outlined"
                >
                    Отменить
                </Button>
            </Flex>
        </ToastPlate>
    );
};
