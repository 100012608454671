/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, FlexColumns, formatDate, ModalContext, Typography,
} from '@alphakits/ui';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import cn from 'classnames';
import { EmptyState } from 'src/components/empty-state';
import { PageLoader } from 'src/components/page-loader';
import { Resource } from 'src/components/schedule/components/recource-list/resource';
import { TableSlot, TimeTable } from 'src/components/time-table';
import { OrderModal } from 'src/modals/order';
import { OrderNewModal } from 'src/modals/order-new';
import { ChangeScheduleModal } from 'src/modals/slots/change-schedule';
import { ManageBreaksModal } from 'src/modals/slots/manage-breaks';
import { UpdateSingleSlotModal } from 'src/modals/slots/update-single-slot';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    selectAllUsers,
    selectLocationById,
    selectLocationSessions,
    SerializedSession,
    SerializedSlot,
} from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { CalendarHeader } from './header';
import { PrintOrderCell } from './print-order-cell';
import { RenderBookingCell } from './render-booking-cell';
import { RenderUserCell } from './render-user-cell';
import { useCalendarData } from './use-calendar-data';

import styles from './index.module.css';

export const CalendarPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const {
        current: { role, user },
    } = useCurrentUser();

    const {
        locationId,
        changeLocation,
        selectedQuestrooms,
        changeQuestrooms,
        orders,
        ordersLoading,
        view,
        date,
        changeView,
        refetch,
        slots,
        loading,
        removeSlot,
        addOrRemoveBreak,
        forceRemoveHoldFetch,
    } = useCalendarData();

    const openOrderModal = openModal(OrderModal, 'm', true);
    const openOrderNewModal = openModal(OrderNewModal);
    const openScheduleTemplateModal = openModal(ChangeScheduleModal, 'full');
    const openSlotsBreakModal = openModal(ManageBreaksModal);

    const users = useAppSelector(selectAllUsers(user.id));
    const location = useAppSelector(selectLocationById(locationId));

    const sessions = useAppSelector(selectLocationSessions(users, date, locationId));

    const dayResources = useMemo(
        () =>
            selectedQuestrooms
                .sort((a, b) => (a.title < b.title ? -1 : 1))
                .map((data) => ({
                    id: data.id,
                    data: {
                        ...data,
                        emptyState: !data.templateId && (
                            <EmptyState title={ t('Нет расписания') }>
                                <Button
                                    size="xs"
                                    view="outlined"
                                    onClick={ () =>
                                        openScheduleTemplateModal({
                                            callback: refetch,
                                            questroomsIds: [data.id],
                                        }) }
                                >
                                    { t('Добавить расписание') }
                                </Button>
                            </EmptyState>
                        ),
                    },
                })),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedQuestrooms],
    );

    const canEditOrders = role.totalAccess || role.accessToOrdersSection;

    const handleOpenOrderModal = (slot: SlotRO) => {
        if (!slot.order) {
            return openOrderNewModal({ initialSlot: slot, successCallback: refetch });
        }

        return openOrderModal({ recordId: slot.order.id, successCalback: refetch });
    };

    const slotDotsActions = (slot: SlotRO) => {
        if (!role.canCreateBreaks && !role.totalAccess) return {};

        if (slot.breakReason) {
            return {
                [t('Удалить перерыв')]: () =>
                    addOrRemoveBreak({ data: { breakReason: null }, id: slot.id }),
                [t('Управлять перерывами')]: () =>
                    openSlotsBreakModal({
                        callback: refetch,
                        questroomId: slot.questroomId,
                        type: 'remove',
                    }),
            };
        }

        if (slot.hold && !slot.order) {
            return {
                [t('Удалить холд')]: () => {
                    if (confirm(t('holdWarning'))) {
                        forceRemoveHoldFetch(slot.hold);
                    }
                },
            };
        }

        if (!slot.order?.id) {
            return {
                [t('openSlotBreakModal')]: () =>
                    openSlotsBreakModal({
                        startDate: slot.date,
                        endDate: slot.date,
                        startTime: slot.start,
                        endTime: slot.end,
                        questroomId: slot.questroomId,
                        type: 'add',
                        callback: refetch,
                    }),
                [t('editSlotTime')]: () =>
                    openModal(UpdateSingleSlotModal)({
                        record: slot,
                        callback: refetch,
                    }),
                [t('deleteSlot')]: () => removeSlot(slot.id),
            };
        }

        return null;
    };

    const allSlots: Array<
        TableSlot<
            { type: 'slot'; record: SerializedSlot } | { type: 'user'; record: SerializedSession }
        >
    > = [
        ...sessions.map((data) => ({
            start: data.start,
            end: data.end,
            date: null,
            resourceId: 'admins',
            id: data.id,
            data: {
                type: 'user' as const,
                record: data,
            },
        })),
        ...slots.map((data) => ({
            start: data.start,
            end: data.end,
            date: data.date,
            resourceId: data.questroomId,
            id: data.id,
            data: {
                type: 'slot' as const,
                record: data,
            },
        })),
    ];

    return (
        <div className={ cn(styles.content, { [styles.content__loading]: loading }) }>
            <CalendarHeader
                selectedLocationId={ locationId }
                date={ date }
                selectedQuestrooms={ selectedQuestrooms }
                view={ view }
                changeView={ changeView }
                refetch={ refetch }
                changeSelectedQuestroomsIds={ changeQuestrooms }
                changeLocation={ changeLocation }
            />

            { /* { view === 'week' && !!questroomsInLocation.length && (
                <TimeTable
                    timeZone={ selectedLocation.timeZone }
                    resources={ weekResources(
                        startOfWeek(date),
                        selectedQuestrooms[0]?.id,
                        'de',
                    ).map((data) => ({ id: data.id, data })) }
                    clockTooltip={ selectedLocation.timeZone }
                    loading={ loading }
                    slots={ slots.map((data) => ({
                        start: data.start,
                        end: data.end,
                        resourceId: data.date,
                        id: data.id,
                        data,
                    })) }
                    renderResource={ (res) => <Resource resource={ res } /> }
                    renderSlot={ (slot) => (
                        <RenderBookingCell
                            slot={ slot.data }
                            view="default"
                            onSlotClick={ canEditOrders && handleOpenOrderModal }
                            slotActions={ slotDotsActions }
                        />
                    ) }
                />
            ) } */ }
            <Typography.Title className="onlyForPrint" view="small" tag="div" weight="bold">
                { formatDate(date, { lang: i18n.language, format: 'dd MMMM yyyy' }) }
            </Typography.Title>

            { view === 'day' && !!selectedQuestrooms.length && (
                <TimeTable
                    timeZone={ location.timeZone }
                    resources={ [
                        {
                            id: 'admins',
                            data: {
                                title: '',
                                slotsWidth: 58,
                            },
                        },
                        ...dayResources,
                    ] }
                    loading={ loading }
                    locationId={ locationId }
                    clockTooltip={ location.timeZone }
                    slots={ allSlots }
                    date={ date }
                    draggable={ true }
                    renderResource={ (res, draggable) => (
                        <Resource draggable={ draggable } resource={ res } />
                    ) }
                    renderSlot={ (slot) => {
                        if (slot.data.type === 'slot') {
                            return (
                                <RenderBookingCell
                                    slot={ slot.data.record }
                                    view="default"
                                    onSlotClick={ canEditOrders && handleOpenOrderModal }
                                    slotActions={ slotDotsActions }
                                />
                            );
                        }

                        if (slot.data.type === 'user') {
                            return <RenderUserCell cb={ refetch } session={ slot.data.record } />;
                        }

                        return null;
                    } }
                />
            ) }

            { view === 'games' && !!selectedQuestrooms.length && (
                <FlexColumns columns={ 1 } gr={ 4 }>
                    { ordersLoading && <PageLoader /> }

                    { !ordersLoading && orders.map((order) => <PrintOrderCell order={ order } />) }

                    { !ordersLoading && orders && !orders.length && (
                        <EmptyState fullView={ true } title="No orders" />
                    ) }
                </FlexColumns>
            ) }

            { view === 'slotsList' && !!selectedQuestrooms.length && (
                <FlexColumns className={ styles.slotsList } columns={ 1 } gr={ 8 }>
                    { slots.map((data) => (
                        <RenderBookingCell
                            slot={ data }
                            view="compact"
                            onSlotClick={ canEditOrders && handleOpenOrderModal }
                            slotActions={ slotDotsActions }
                        />
                    )) }
                </FlexColumns>
            ) }

            { !selectedQuestrooms.length && (
                <EmptyState
                    title={ t('emptyStates.noQuestrooms.title') }
                    subtitle={ t('emptyStates.noQuestrooms.description') }
                    fullView={ true }
                />
            ) }
        </div>
    );
};
