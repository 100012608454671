import React from 'react';
import { InfoMarkM, MessageMarkM } from '@alphakits/icons';
import {
    Flex, FlexColumns, formatDate, Tooltip, Typography,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { DaySessionsRO } from '@escapenavigator/types/dist/user/sessions/day-sessions.ro';
import { convertHHMMToMinutes } from '@escapenavigator/utils/dist';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { format } from 'date-fns';
import { convertSecondsToHHMMSS } from 'src/components/schedule/utils/convert-seconds-to-hhmmss';
import { UserCell } from 'src/components/user-cell';
import { selectLocationById, selectUserById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

const User: React.FC<{ id: number }> = ({ id }) => {
    const user = useAppSelector(selectUserById(id));

    if (!id) return null;

    return <React.Fragment>{ getFullName(user) }</React.Fragment>;
};

const Location: React.FC<{ id: number }> = ({ id }) => {
    const location = useAppSelector(selectLocationById(id));

    if (!id) return null;

    return <React.Fragment>{ location.title }</React.Fragment>;
};

export const useSessionsColumns = ({ i18n, t }): TableColumns<DaySessionsRO> => {
    const getWorkingTime = (row: DaySessionsRO) =>
        convertSecondsToHHMMSS(
            row.sessions.reduce((acc, session) => {
                let minutes =
                    convertHHMMToMinutes(session.end) - convertHHMMToMinutes(session.start);

                if (session.end < session.start) {
                    minutes += 24 * 60;
                }

                return acc + minutes * 60;
            }, 0),
        );

    return [
        {
            header: t('employee'),
            sort: true,
            accessor: 'userId',
            row: {
                title: ({ row }) => <UserCell id={ row.userId } size="m" />,
            },
        },
        {
            header: t('filters.date'),
            sort: true,
            accessor: 'date',
            row: {
                title: ({ row }) =>
                    formatDate(row.date, { lang: i18n.language, format: 'dd MMMM yyyy' }),
            },
        },
        {
            header: t('Рабочее время'),
            accessor: 'id',
            sort: true,
            row: {
                title: ({ row }) => getWorkingTime(row),
                subtitle: ({ row }) =>
                    (
                        <React.Fragment>
                            { row.sessions.map((s) => (
                                <React.Fragment>
                                    { s.start } - { s.end }, <Location id={ s.locationId } />
                                    <br />
                                </React.Fragment>
                            )) }
                        </React.Fragment>
                    ) as any as string,
            },
        },
        {
            header: '',
            accessor: 'comment',
            sort: true,
            row: {
                title: ({ row }) => (
                    <Flex gap="md">
                        { row.comment && (
                            <Tooltip
                                trigger="hover"
                                position="bottom-end"
                                content={ (
                                    <div style={ { width: 300, overflow: 'auto' } }>
                                        <Typography.Text view="caps" color="secondary">
                                            { row.comment }
                                        </Typography.Text>
                                    </div>
                                ) }
                            >
                                <MessageMarkM />
                            </Tooltip>
                        ) }
                        <Tooltip
                            trigger="hover"
                            position="bottom-end"
                            content={ (
                                <div style={ { width: 300, overflow: 'auto' } }>
                                    <FlexColumns columns={ 1 } gr={ 16 }>
                                        <Typography.Text view="caps" color="secondary">
                                            Created{ ' ' }
                                            { format(new Date(row.createdAt), 'dd MMMM yyyy, HH:mm') }
                                            , <User id={ row.lastEditorId } />
                                        </Typography.Text>
                                        { row.createdAt !== row.updatedAt && (
                                            <Typography.Text view="caps" color="secondary">
                                                Updated{ ' ' }
                                                { format(
                                                    new Date(row.updatedAt),
                                                    'dd MMMM yyyy, HH:mm',
                                                ) }
                                                , <User id={ row.lastEditorId } />
                                            </Typography.Text>
                                        ) }
                                    </FlexColumns>
                                </div>
                            ) }
                        >
                            <InfoMarkM />
                        </Tooltip>
                    </Flex>
                ),
            },
        },
    ];
};
