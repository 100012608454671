import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, ModalContext, Sidepanel, Table, ToastPlate, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';
import { WebhookModal } from './create';

import styles from './index.module.css';

export const WebhooksManagerModal: React.FC = () => {
    const { webhooksApi } = useApi();
    const { openModal } = useContext(ModalContext);
    const { t, i18n } = useTranslation();

    const { queryData, queryLoading, queryFetch } = useApiMethod({
        api: webhooksApi.query,
    });

    const { apiData, apiLoading, apiFetch } = useApiMethod({
        api: webhooksApi.api,
    });

    const { removeLoading, removeFetch } = useApiMethod({
        api: webhooksApi.remove,
        successCallback: () => queryFetch(undefined),
    });

    const openSmtp = () =>
        openModal(WebhookModal)({
            cb: () => queryFetch(undefined),
        });

    useEffect(() => {
        queryFetch(undefined);
        apiFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = Columns({
        remove: removeFetch,
    });

    return (
        <Sidepanel
            title="Webhooks"
            headerRightAddons={ (
                <Button view="outlined" size="xs" onClick={ () => openSmtp() }>
                    { t('Добавить') }
                </Button>
            ) }
        >
            <Table.TableComponent
                columns={ columns }
                hideSearch={ true }
                height="default"
                language={ getLanguage(i18n) }
                records={ queryData }
                loading={ removeLoading || queryLoading }
            />

            { !apiLoading && apiData && (
                <div style={ { padding: '36px 0' } }>
                    <ToastPlate view="neutral">
                        <Typography.Text view="primary-small" tag="div">
                            <p>
                                <strong>Webhook</strong> is a mechanism for sending automatic event
                                notifications to a client-specified URL using a{ ' ' }
                                <strong>POST request</strong>.
                            </p>

                            <h2>How the Webhook Works:</h2>
                            <ul>
                                <li>
                                    When a game is created, canceled, or updated, the system
                                    generates an object containing order data.
                                </li>
                                <li>
                                    A <strong>POST request</strong> is sent to the URL provided by
                                    the client.
                                </li>
                                <li>The request body includes the following parameters:</li>
                                <ul>
                                    <li>
                                        <strong>action</strong> – one of the following values:
                                    </li>
                                    <ul>
                                        <li>
                                            <code>new</code> – for creating a booking.
                                        </li>
                                        <li>
                                            <code>cancel</code> – for canceling a booking.
                                        </li>
                                        <li>
                                            <code>update</code> – for updating booking details.
                                        </li>
                                    </ul>
                                    <li>
                                        <strong>type</strong> – a string <code>booking</code>,
                                        indicating the event type.
                                    </li>
                                    <li>
                                        <strong>data</strong> – an <code>Order</code> object
                                        containing booking information:
                                    </li>
                                </ul>
                            </ul>
                        </Typography.Text>

                        <table className={ styles.table } cellPadding="10" cellSpacing="0">
                            <tbody>
                                { Object.values(apiData).map((value: any) => (
                                    <tr key={ value.property }>
                                        <td>
                                            <b>{ value.property }</b>
                                        </td>
                                        <td>{ value.metadata.description }</td>
                                        <td>{ value.metadata.type }</td>
                                        <td>
                                            <pre>
                                                { JSON.stringify(value.metadata.example, null, 4) }
                                            </pre>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </ToastPlate>
                </div>
            ) }

            { /* <pre>
                {JSON.stringify()}
                { JSON.stringify({
                    createdAt: '2024-03-01T12:00:00.000Z',
                    updatedAt: '2024-03-01T12:00:00.000Z',
                    date: '2024-03-01T14:00:00',
                    source: 'widget | user | agregator | exported | support',
                    code: 'BOOK123',
                    confirmed: true,
                    payed: 40,
                    total: 100,
                    players: 3,

                    client: {
                        birthday: '1990-06-15',
                        name: 'Alice',
                        surname: 'Smith',
                        phone: '+1234567890',
                        phone2: '+0987654321',
                        email: 'alice@example.com',
                        address: '123 Main St',
                        postcode: '10001',
                        city: 'New York',
                        subscribedToNewsletter: true,
                        ordersCount: 5,
                        certificatesCount: 2,
                        blockedDate: null,
                    },

                    utm: {
                        utmSource: 'google',
                        utmMedium: 'cpc',
                        utmCampaign: 'spring_sale',
                        utmContent: 'banner1',
                        utmTerm: 'escape room',
                    },

                    customFields: [
                        {
                            title: 'Special Request',
                            value: 'Need wheelchair access',
                        },
                    ],

                    language: 'en',
                    mode: 'Hard (if you use modes)',

                    moderator: {
                        name: 'Alex Kallas (if moderator assigned)',
                    },

                    canceled: false,
                    canceledWithFine: false,
                    cancelationType: 'client',

                    result: 85,
                    resultRating: 4.5,
                    photos: ['https://example.com/photo1.jpg'],
                    comment: 'with children!',

                    questroom: {
                        title: 'The Saw',
                    },
                    certificates: [
                        {
                            createdAt: '2024-01-10T09:00:00.000Z',
                            nominal: 50,
                            code: 'GIFT50',
                        },
                    ],
                    uploadedCertificates: [],
                    upsellings: [
                        {
                            createdAt: '2024-02-25T11:00:00.000Z',
                            title: 'Extra Time',
                            comment: 'Extra 15 minutes',
                            amount: 10,
                        },
                    ],
                    discounts: [
                        {
                            createdAt: '2024-02-25T11:15:00.000Z',
                            reason: 'Loyalty Program',
                            amount: 5,
                        },
                    ],
                    promocodes: [
                        {
                            createdAt: '2024-02-25T11:20:00.000Z',
                            amount: 10,
                            code: 'SPRING2024',
                        },
                    ],
                    penalties: [],

                }, null, 2) }
            </pre> */ }
        </Sidepanel>
    );
};
