/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSelectable, SelectableGroup } from 'react-selectable';
import {
    Button,
    CalendarInput,
    Cell,
    Checkbox,
    Divider,
    Flex,
    FlexColumns,
    formatDate,
    ModalContext,
    showError,
    Tooltip,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { SlotTemplateResponseObject } from '@escapenavigator/types/dist/slot-template/slot-template.ro';
import { UpdateSlotTemplateDto } from '@escapenavigator/types/dist/slot-template/update-slot-template.dto';
import { convertHHMMToMinutes, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { format } from 'date-fns';
import { Resource } from 'src/components/schedule/components/recource-list/resource';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { TimeTable } from 'src/components/time-table';
import { useApi } from 'src/providers/api/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import { selectAllQuestrooms, selectAllTariffs, upsertQuestroom } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useRulesApi } from 'src/store/hooks/use-rules';

import { Autocomplite } from '../autocomplite';
import { BookingRuleDescription } from '../booking-rules-description';
import { ScheduleSlot } from '../schedule-slot';

import { CopyTemplatePicker } from './copy-template-picker';
import { SlotsMenu } from './slots-menu';
import {
    addSlots,
    daysOfWeekNames,
    deleteSlots,
    editSlots,
    getDaysOfWeek,
    ScheduleSlot as ScheduleSlotType,
} from './utils';

import styles from './index.module.css';

export type SerializedTemplate = {
    questroom: QuestroomRO;
    hash: number;
    schedule: SlotTemplateResponseObject;
};

type Props = {
    close: () => void;
    questroomsIds?: number[];
    callback?: () => void;
};

const SelectableComponent = createSelectable(({ children }) => <div>{ children }</div>);

export const ChangeScheduleModal: React.FC<Props> = ({ close, callback, questroomsIds }) => {
    const { t, i18n } = useTranslation();
    const { slotTemplates } = useApi();

    const { openModal } = useContext(ModalContext);
    const dispatch = useAppDispatch();

    const { rules, loading: rulesLoading } = useRulesApi();
    const { refetchProgress } = useOnboarding();

    const [infiniteExtention, setInfiniteExtention] = useState(true);
    const [slots, setSlots] = useState<ScheduleSlotType[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [questroomTime, setQuestroomTime] = useState<number>();

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const tariffs = useAppSelector(selectAllTariffs);

    const { getAllData, getAllLoading, getAllFetch } = useApiMethod({
        api: slotTemplates.getAll,
        successCallback: ({ data }) => {
            if (!questroomsIds?.length) return;

            const initialSlots = data.find((template) => template.questroomId === questroomsIds[0]);

            if (initialSlots) {
                setSlots(
                    addSlots({
                        initial: [],
                        slots: initialSlots.slots,
                    }),
                );
            }
        },
    });

    const { updateFetch, updateLoading } = useApiMethod({
        api: slotTemplates.update,
        successCallback: ({ data }) => {
            data.forEach((questroom) => dispatch(upsertQuestroom(questroom)));

            if (callback) callback();
            refetchProgress();
            close();
        },
        errorCallback: ({ message }) => showError(message),
    });

    useEffect(() => {
        getAllFetch(undefined);

        if (questroomsIds?.length) {
            setQuestroomTime(
                questroomsIds ? questrooms.find((q) => q.id === questroomsIds[0]).time : undefined,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schedules: SerializedTemplate[] = getAllData
        ? getAllData.map((s) => ({
            questroom: questrooms.find((q) => q.id === s.questroomId),
            hash: s.slots.reduce(
                (acc, slot) =>
                    (acc +=
                          +slot.date +
                          +convertHHMMToMinutes(slot.start) +
                          +slot.ruleId +
                          +slot.tariffId),
                0,
            ),
            schedule: s,
        }))
        : [];

    const onSlotClick = (slotId: string) => {
        setSelectedIds(
            selectedIds.includes(slotId)
                ? selectedIds.filter((s) => s !== slotId)
                : [...selectedIds, slotId],
        );
    };

    const handleSaveForm = (values: UpdateSlotTemplateDto) => {
        updateFetch({ data: { ...values, slots: slots.map((s) => s.slot) } });
    };

    const openAutocompleteModal = (time?: string, day?: number) => {
        openModal(Autocomplite)({
            submit: (newSlots) => {
                setSlots(
                    addSlots({
                        initial: slots,
                        slots: newSlots,
                    }),
                );
                setSelectedIds([]);
            },
            questroomTime,
            initialStart: time,
            initialDay: day,
        });
    };

    const handleChangeQuestrooms = (ids: number[] = []) => {
        if (!ids.length) {
            setSlots([]);

            return;
        }

        if (slots.length) return;

        const schedule = schedules.find(({ schedule }) => ids.includes(schedule.questroomId));

        if (schedule) {
            setSlots(
                addSlots({
                    initial: [],
                    slots: schedule.schedule.slots,
                }),
            );
        }

        setQuestroomTime(ids.length ? questrooms.find((q) => ids[0] === q.id).time : undefined);
    };

    return (
        <RestForm
            title={ `${t('Изменение расписания')}` }
            initialValues={ serializeRecord(UpdateSlotTemplateDto, {
                questroomsIds,
                start: format(new Date(), 'yyyy-MM-dd'),
                slots: [],
            }) }
            submitButtonText={ t('openScheduleTemplateModal') }
            loading={ getAllLoading || !getAllData || updateLoading || rulesLoading }
            validate={ validateByDto(t) }
            save={ handleSaveForm }
            close={ close }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue, validateForm, setTouched,
            }) => {
                const daysOfWeek = getDaysOfWeek(values.start, values.end);

                const handleValidateForm = (cb) => {
                    setTouched(
                        Object.fromEntries(Object.entries(values).map(([key]) => [key, true])),
                    );

                    validateForm().then((err) => {
                        if (Object.keys(err).length) return;

                        cb();
                    });
                };

                const hasSchedule = questrooms
                    .filter((q) => values.questroomsIds?.includes(q.id))
                    .every((q) => q.templateId);

                return (
                    <Flex align="stretch" className={ styles.container }>
                        <Flex className={ styles.form } direction="column" gap="xl">
                            <FlexColumns columns={ 1 } gr={ 32 }>
                                <FlexColumns columns={ 1 }>
                                    <QuestroomsSelect
                                        multiple={ true }
                                        questroomtimeRule={ true }
                                        label={ t('Один или несколько квестов') }
                                        questrooms={ questrooms }
                                        selected={ values.questroomsIds }
                                        error={
                                            touched.questroomsIds &&
                                            (errors.questroomsIds as string)
                                        }
                                        onChange={ ({ selectedMultiple }) => {
                                            const ids = selectedMultiple.map((q) => +q.key);

                                            setFieldValue('questroomsIds', ids);
                                            handleChangeQuestrooms(ids);
                                        } }
                                    />

                                    <FlexColumns columns={ hasSchedule ? 2 : 1 }>
                                        <CalendarInput
                                            lang={ i18n.language }
                                            label={ t('Дата начала') }
                                            value={ values.start }
                                            onChange={ (e, { value }) => {
                                                if (values.end <= value) {
                                                    setFieldValue('end', undefined);
                                                }
                                                setFieldValue('start', value);
                                            } }
                                            block={ true }
                                            error={ touched.start && errors.start }
                                        />

                                        { hasSchedule && (
                                            <CalendarInput
                                                lang={ i18n.language }
                                                label={ t('Дата окончания') }
                                                disabled={ infiniteExtention }
                                                minDate={
                                                    +formatDate(values.start, {
                                                        lang: i18n.language,
                                                        format: 'T',
                                                    })
                                                }
                                                value={ values.end }
                                                onChange={ (e, { value }) =>
                                                    setFieldValue('end', value) }
                                                block={ true }
                                            />
                                        ) }
                                    </FlexColumns>
                                    { hasSchedule && (
                                        <Checkbox
                                            label={ t('Бесконечное продление') }
                                            checked={ infiniteExtention }
                                            onChange={ (e, { checked }) => {
                                                setInfiniteExtention(checked);
                                                setFieldValue('end', '');
                                            } }
                                            block={ true }
                                        />
                                    ) }
                                </FlexColumns>

                                <FlexColumns columns={ 1 } gr={ 16 }>
                                    <div>
                                        <Button
                                            view="outlined"
                                            size="xs"
                                            onClick={ () =>
                                                handleValidateForm(openAutocompleteModal) }
                                        >
                                            { t('Заполнить автоматически') }
                                        </Button>
                                    </div>

                                    <CopyTemplatePicker
                                        templates={ schedules }
                                        onChange={ (s) => {
                                            handleValidateForm(() =>
                                                setSlots(
                                                    addSlots({
                                                        initial: [],
                                                        slots: s,
                                                    }),
                                                ));
                                        } }
                                    />
                                </FlexColumns>
                            </FlexColumns>
                        </Flex>

                        <Divider size="l" view="vertical" />

                        <SelectableGroup
                            className={ styles.calendar }
                            onSelection={ (items) => {
                                if (!items.length) return;

                                setSelectedIds(items);
                            } }
                        >
                            { !!selectedIds.length && (
                                <SlotsMenu
                                    selectedIds={ selectedIds }
                                    setSelectedIds={ setSelectedIds }
                                    deleteSlots={ () =>
                                        setSlots(deleteSlots({ initial: slots, ids: selectedIds })) }
                                    editSlots={ (values) =>
                                        setSlots(
                                            editSlots({
                                                initial: slots,
                                                ids: selectedIds,
                                                values,
                                            }),
                                        ) }
                                    slots={ slots }
                                />
                            ) }
                            <TimeTable
                                resources={ daysOfWeek.map((data) => ({
                                    id: data,
                                    data: {
                                        id: data,
                                        title: daysOfWeekNames[data],
                                    },
                                })) }
                                view="compact"
                                onEmptyIntervalClick={ (time, day) =>
                                    handleValidateForm(() => openAutocompleteModal(time, day)) }
                                clockTooltip=""
                                loading={ getAllLoading }
                                slots={ slots.map((data, i) => ({
                                    start: data.slot.start,
                                    end: data.slot.end,
                                    date: data.slot.date,
                                    resourceId: data.slot.date,
                                    id: i,
                                    data,
                                })) }
                                renderResource={ (res) => <Resource resource={ res } /> }
                                renderSlot={ ({ data }) => {
                                    const selected = selectedIds.includes(data.id);
                                    const tariff = tariffs.find(
                                        (tariff) => tariff.id === data.slot.tariffId,
                                    );
                                    const rule = rules.find((rule) => rule.id === data.slot.ruleId);

                                    return (
                                        <SelectableComponent
                                            key={ data.id }
                                            selected={ selected }
                                            component=""
                                            selectableKey={ data.id }
                                        >
                                            <Tooltip
                                                trigger="hover"
                                                position="bottom"
                                                onOpenDelay={ 1000 }
                                                offset={ [0, 40] }
                                                content={ (
                                                    <FlexColumns columns={ 1 } gr={ 8 }>
                                                        { tariff && (
                                                            <Cell.Base
                                                                title={ t('Тариф') }
                                                                subtitle={ JSON.stringify(
                                                                    tariff?.price,
                                                                ) }
                                                            />
                                                        ) }

                                                        <BookingRuleDescription rule={ rule } />
                                                    </FlexColumns>
                                                ) }
                                            >
                                                <div>
                                                    <ScheduleSlot
                                                        color={ data.color }
                                                        selected={ selected }
                                                        title={ data.slot.start }
                                                        onClick={ () => onSlotClick(data.id) }
                                                    />
                                                </div>
                                            </Tooltip>
                                        </SelectableComponent>
                                    );
                                } }
                            />
                        </SelectableGroup>
                    </Flex>
                );
            } }
        </RestForm>
    );
};
