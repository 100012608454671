import React from 'react';
import { DotsM } from '@alphakits/icons';
import {
    AmountInput,
    Checkbox,
    Collapse,
    FlexColumns,
    ImageUploader,
    Input,
    Loader,
    Select,
    showError,
    SuperListHeader,
    Textarea,
    ToastPlate,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CashboxTypeEnum } from '@escapenavigator/types/dist';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { CreateQuestroomDto } from '@escapenavigator/types/dist/questroom/create-questroom.dto';
import { QuestroomActorsEnum } from '@escapenavigator/types/dist/questroom/enum/questroom-actors.enum';
import { QuestroomTypeEnum } from '@escapenavigator/types/dist/questroom/enum/questroom-type.enum';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { convertToOptions } from '@escapenavigator/utils/dist/convert-to-options';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { Picker } from 'src/components/picker';
import { FeedbackTextarea } from 'src/pages/marketing/sales/common/feedback-textarea';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    removeQuestroom,
    selectAllCashboxes,
    selectAllLocations,
    upsertQuestroom,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { getOptions } from './options';
import { QuestroomTagsPicker } from './tags-selector';

type Props = {
    questroom?: QuestroomRO;
    close: () => void;
    t: TFunction;
    locationId?: LocationRO['id'];
};

export const QuestroomModal: React.FC<Props> = ({
    close, t, locationId, questroom,
}) => {
    const recordId = questroom?.id;
    const dispatch = useAppDispatch();
    const {
        current: { mode },
    } = useCurrentUser();
    const { questrooms: questroomsApi, uploads } = useApi();

    const questroomTypes = Object.values(QuestroomTypeEnum).map((v) => ({
        key: v,
        content: t(`common:types.${v}`),
    }));

    const locations = useAppSelector(selectAllLocations);
    const cashboxes = useAppSelector(selectAllCashboxes);

    const initialValues: Partial<CreateQuestroomDto> = questroom || {
        time: 60,
        minAge: 12,
        difficult: 4,
        locales: [],
        modes: [],
        fear: 1,
        onlinePaymentsCashbox: cashboxes.filter((c) => c.type === CashboxTypeEnum.STRIPE)[0]?.id,
        bsCashbox: cashboxes.filter((c) => c.type === CashboxTypeEnum.BS)[0]?.id,
        paypalCashbox: cashboxes.filter((c) => c.type === CashboxTypeEnum.PAYPAL)[0]?.id,
    };

    if (locationId) {
        initialValues.locationId = locationId;
    }

    const { closeManyFetch, closeManyLoading } = useApiMethod({
        api: questroomsApi.closeMany,
        successCallback: ({ data }) => {
            dispatch(upsertQuestroom(data[0]));
            close();
        },
        errorCallback: ({ message }) => showError(message),
    });

    const { removeFetch, removeLoading } = useApiMethod({
        api: questroomsApi.remove,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => {
            dispatch(removeQuestroom(recordId));
            close();
        },
    });

    const {
        save,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? questroomsApi.update : questroomsApi.create,
        saveCallback: (savedRecord) => {
            dispatch(upsertQuestroom(savedRecord));
        },
        close,
    });

    if (questroom?.deletedAt) {
        return <ToastPlate view="default" title="This questroom is deleted" />;
    }

    let title = questroom?.title || t('modalsHeader.questroom');

    if (questroom?.closed) {
        title = `[CLOSED] ${title}`;
    }

    return (
        <RestForm
            recordId={ +recordId }
            title={ title }
            initialValues={ serializeRecord(CreateQuestroomDto, initialValues) }
            validate={ validateByDto(t) }
            save={ save }
            headerAddon={
                recordId && (
                    <Picker
                        t={ t }
                        content={ removeLoading || closeManyLoading ? <Loader /> : <DotsM /> }
                        actions={
                            questroom?.closed
                                ? {
                                    [t('Квест снова работает')]: () =>
                                        closeManyFetch({ ids: [recordId], closed: false }),
                                    [t('Удалить квест')]: () => removeFetch(recordId),
                                }
                                : {
                                    [t('Квест закрыт')]: () =>
                                        closeManyFetch({ ids: [recordId], closed: true }),
                                }
                        }
                    />
                )
            }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="title"
                            label={ t('component.questroomTitle') }
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                            block={ true }
                        />
                        <Select
                            dataTestId="location"
                            label={ t('address') }
                            options={ convertToOptions(locations) }
                            allowUnselect={ true }
                            error={ touched.locationId && errors.locationId }
                            required={ true }
                            selected={ values.locationId }
                            onChange={ ({ selected }) => {
                                setFieldValue('locationId', +selected?.key);
                            } }
                            block={ true }
                            optionsListWidth="field"
                        />

                        <Select
                            dataTestId="type"
                            label={ t('tables.type') }
                            options={ questroomTypes }
                            error={ touched.type && errors.type }
                            required={ true }
                            selected={ `${values.type}` }
                            onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                            block={ true }
                            optionsListWidth="field"
                        />

                        <Select
                            dataTestId="actors"
                            label={ t('component.hasActors') }
                            options={ enumToOptions(QuestroomActorsEnum).map((o) => ({
                                ...o,
                                content: t(`common:actors.${o.key}`),
                            })) }
                            error={ touched.actors && errors.actors }
                            required={ true }
                            selected={ `${values.actors}` }
                            onChange={ ({ selected }) => setFieldValue('actors', selected?.key) }
                            block={ true }
                            optionsListWidth="field"
                        />

                        <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                            <AmountInput
                                dataTestId="time"
                                label={ t('component.timeGame') }
                                suffix={ 'min' as any }
                                value={ values.time }
                                error={ touched.time && errors.time }
                                onChange={ (e, { value }) => setFieldValue('time', value) }
                                block={ true }
                            />

                            <Select
                                dataTestId="minAge"
                                label={ t('component.minAge') }
                                options={ [0, 6, 8, 10, 12, 14, 16, 18].map((i) => ({
                                    key: +i,
                                    content: `${i}+`,
                                })) }
                                selected={ values.minAge }
                                error={ touched.minAge && errors.minAge }
                                required={ true }
                                onChange={ ({ selected }) => setFieldValue('minAge', selected?.key) }
                                block={ true }
                                optionsListWidth="field"
                            />

                            <Select
                                dataTestId="difficult"
                                label={ t('component.difficult') }
                                options={ getOptions(1, 6).map((i) => ({
                                    key: +i.key,
                                    content: t(`common:difficult.${+i.key}`),
                                })) }
                                selected={ values.difficult }
                                error={ touched.difficult && errors.difficult }
                                required={ true }
                                onChange={ ({ selected }) =>
                                    setFieldValue('difficult', selected?.key) }
                                block={ true }
                                optionsListWidth="field"
                            />

                            <Select
                                dataTestId="fear"
                                label={ t('component.fear') }
                                options={ getOptions(1, 5).map((i) => ({
                                    key: +i.key,
                                    content: t(`common:fear.${+i.key}`),
                                })) }
                                selected={ values.fear }
                                error={ touched.fear && errors.fear }
                                required={ true }
                                onChange={ ({ selected }) => setFieldValue('fear', selected?.key) }
                                block={ true }
                                optionsListWidth="field"
                            />
                        </FlexColumns>

                        { /* <Select
                            dataTestId="tags"
                            block={ true }
                            multiple={ true }
                            optionsListWidth="field"
                            label={ t('questroomTags') }
                            options={ questroomTags }
                            error={ touched.questroomTags && (errors.questroomTags as string) }
                            selected={ values.questroomTags }
                            onChange={ ({ selectedMultiple }) =>
                                setFieldValue(
                                    'questroomTags',
                                    selectedMultiple.map((tag) => tag.key),
                                ) }
                        /> */ }

                        <FeedbackTextarea
                            id="legend"
                            text={ values.legend }
                            title={ t('component.legend') }
                            lang={ values.language }
                            error={ touched.legend && errors.legend }
                            handlers={ [] }
                            noLinks={ true }
                            onChange={ handleChange('legend') }
                        />

                        <FeedbackTextarea
                            id="importantInfo"
                            text={ values.importantInfo }
                            title={ t('component.importantInfo') }
                            lang={ values.language }
                            error={ touched.importantInfo && errors.importantInfo }
                            noLinks={ true }
                            handlers={ [] }
                            onChange={ handleChange('importantInfo') }
                        />

                        <Textarea
                            label={ t('Тизер') }
                            value={ values.teaser || '' }
                            onChange={ handleChange('teaser') }
                            block={ true }
                            hint={ t('ТизерХинт') }
                            maxLength={ 1000 }
                        />

                        <FlexColumns columns={ 1 } gr={ 4 } gc={ 0 }>
                            <SuperListHeader text={ t('component.minMaxPlayers') } />

                            <FlexColumns columns={ 2 } gr={ 16 } gc={ 20 }>
                                <Select
                                    dataTestId="playersMin"
                                    label={ t('component.playersMin') }
                                    options={ getOptions(1, 20) }
                                    selected={ values.playersMin }
                                    error={ touched.playersMin && errors.playersMin }
                                    onChange={ ({ selected }) =>
                                        setFieldValue('playersMin', selected?.key) }
                                    block={ true }
                                />

                                <Select
                                    dataTestId="playersMax"
                                    label={ t('component.playersMax') }
                                    error={ touched.playersMax && errors.playersMax }
                                    options={ getOptions(1, 50) }
                                    selected={ values.playersMax }
                                    onChange={ ({ selected }) =>
                                        setFieldValue('playersMax', selected?.key) }
                                    block={ true }
                                />
                            </FlexColumns>
                        </FlexColumns>
                        <FlexColumns columns={ 1 } gr={ 4 } gc={ 0 }>
                            <SuperListHeader text={ t('questroomTags') } />
                            <QuestroomTagsPicker
                                tags={ values.questroomTags }
                                setTags={ (tags) => setFieldValue('questroomTags', tags) }
                                error={ touched.questroomTags && (errors.questroomTags as string) }
                            />
                        </FlexColumns>

                        <ImageUploader
                            title={ `${t('component.photoUploaderTitle')} *` }
                            subtitle={ t('component.photoUploaderSubtitle') }
                            value={ values.photos || [] }
                            main={ values.photo }
                            onUpdate={ (value) => {
                                if (!values.photo || !values.photos?.length) {
                                    setFieldValue('photo', value[0]);
                                }
                                setFieldValue('photos', value);
                            } }
                            setMain={ (value) => setFieldValue('photo', value) }
                            error={ touched.photo && errors.photo }
                            uploadRequest={ uploads.uploadImage }
                            type={ ImageTypeEnum.QUESTROOM }
                            multiple={ true }
                        />

                        <br />
                    </FlexColumns>

                    <Collapse
                        collapsedLabel={ t('Продвинутые настройки') }
                        expandedLabel={ t('Скрыть') }
                        dataTestId="minHoursForBookingCollapse"
                    >
                        <FlexColumns columns={ 1 } gr={ 20 }>
                            <Select
                                dataTestId="daysForBooking"
                                label={ t('daysForBooking') }
                                error={ touched.daysForBooking && errors.daysForBooking }
                                options={ getOptions(30, 210, t('common:дней'), 30) }
                                selected={ values.daysForBooking }
                                onChange={ ({ selected }) =>
                                    setFieldValue('daysForBooking', selected?.key) }
                                block={ true }
                            />

                            <Checkbox
                                label={ t('needConfirm') }
                                checked={ values.needConfirm }
                                hint={ t('needConfirmHint') }
                                onChange={ (e, { checked }) => setFieldValue('needConfirm', checked) }
                                block={ true }
                            />

                            <ImageUploader
                                title={ `${t('Рамка для фото участников')}` }
                                value={ values.photoFrame ? [values.photoFrame] : [] }
                                onUpdate={ (value) => setFieldValue('photoFrame', value[0] || null) }
                                uploadRequest={ uploads.uploadImage }
                                type={ ImageTypeEnum.QUESTROOM }
                                multiple={ false }
                            />

                            { mode === 'admin' && (
                                <React.Fragment>
                                    <Checkbox
                                        label={ t('component.onlineSaleForAgregator') }
                                        checked={ values.awailableForNavigator }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('awailableForNavigator', checked) }
                                        block={ true }
                                    />
                                    <Checkbox
                                        label={ t('component.onlineSaleForWidget') }
                                        checked={ values.awailableForWidgets }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('awailableForWidgets', checked) }
                                        block={ true }
                                    />
                                </React.Fragment>
                            ) }
                        </FlexColumns>
                    </Collapse>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
