import React from 'react';
import { TrashS } from '@alphakits/icons';
import { Button, Flex } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { SmtpRO } from '@escapenavigator/types/dist/profile/smtp/smtp.ro';
import { TFunction } from 'i18next';

export const Columns = ({
    locations,
    t,
    removeSmtpFetch,
    testSmtpFetch,
}: {
    locations: LocationRO[];
    t: TFunction;
    removeSmtpFetch: (id: number) => void;
    testSmtpFetch: (params: { id: number }) => void;
}): TableColumns<SmtpRO> => [
    {
        header: 'Email',
        accessor: 'user',
    },
    {
        header: t('Локации'),
        accessor: 'allLocations',
        row: {
            title: ({ row }) =>
                (row.allLocations
                    ? 'All locations'
                    : locations
                        ?.filter((l) => row.locationIds?.includes(l.id))
                        .map((l) => l.title)
                        .join(', ')),
        },
    },
    {
        header: '',
        accessor: 'id',
        width: '100px',
        row: {
            title: ({ row }) => (
                <Flex gap="md">
                    <Button
                        onClick={ (e) => {
                            e.stopPropagation();
                            testSmtpFetch({ id: row.id });
                        } }
                        view="outlined"
                        size="xs"
                    >
                        Test
                    </Button>
                    <Button
                        onClick={ (e) => {
                            e.stopPropagation();
                            removeSmtpFetch(row.id);
                        } }
                        view="outlined"
                        size="xs"
                        leftAddons={ <TrashS /> }
                    />
                </Flex>
            ),
        },
    },
];
