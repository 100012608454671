import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CheckmarkCircleS, ClockCircleS, CloseS, UpdateS,
} from '@alphakits/icons';
import {
    Amount,
    CopyButton,
    Flex,
    FlexColumns,
    formatDate,
    IconButton,
    Loader,
    Padding,
    showError,
    SuperListItem,
    Tooltip,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TransactionSourceEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-source.enum';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectCashboxById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import {
    getTransactionStatusColor,
    getTransactionStatusText,
} from 'src/utils/get-transaction-status';

export type RefundProps = {
    cashboxId: number;
    refundAmount: number;
    comment: string;
};

type Props = {
    transaction: OrderRO['transactions'][0] | CertificateSaleRO['transactions'][0];
    type: 'order' | 'certificate';
    setData: (data: OrderRO | CertificateSaleRO) => void;
};

export const Transaction: React.FC<Props> = ({ transaction, setData, type }) => {
    const cashbox = useAppSelector(selectCashboxById(transaction.cashboxId));
    const { t, i18n } = useTranslation();

    const { orders, certificatesales } = useApi();

    const orderRecoverFetch = useApiMethod({
        api: orders.recoverTransaction,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => setData(data),
    });

    const orderDeleteFetch = useApiMethod({
        api: orders.deleteTransaction,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => setData(data),
    });

    const certificateRecoverFetch = useApiMethod({
        api: certificatesales.recoverTransaction,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => setData(data),
    });

    const certificateDeleteFetch = useApiMethod({
        api: certificatesales.deleteTransaction,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => setData(data),
    });

    const {
        profile: { currency },
    } = useCurrentUser();

    const text = transaction.amount < 0 ? t('Возврат') : t('Платеж');

    const status = getTransactionStatusText(transaction.type, transaction.deleted, t);
    const increase =
        transaction.paymentId && transaction.provider === TransactionSourceEnum.INSIDE
            ? 0
            : transaction.includedIncrease;

    const onRemove = () => {
        if (type === 'order' && transaction.deleted) {
            return orderRecoverFetch.recoverTransactionFetch({ id: transaction.id });
        }

        if (type === 'order' && !transaction.deleted) {
            return orderDeleteFetch.deleteTransactionFetch({ id: transaction.id });
        }

        if (type === 'certificate' && !transaction.deleted) {
            return certificateDeleteFetch.deleteTransactionFetch({ id: transaction.id });
        }

        return certificateRecoverFetch.recoverTransactionFetch({ id: transaction.id });
    };

    const isOnline = transaction.type !== TransactionTypeEnum.INSIDE;

    const loading =
        orderRecoverFetch.recoverTransactionLoading ||
        orderDeleteFetch.deleteTransactionLoading ||
        certificateRecoverFetch.recoverTransactionLoading ||
        certificateDeleteFetch.deleteTransactionLoading;

    return (
        <div style={ { opacity: status ? 0.5 : 1 } }>
            { !!increase && (
                <Padding padding="0 0 12px 0">
                    <SuperListItem
                        text={ `${t('Наценка при онлайн-оплате')}` }
                        disabled={ true }
                        color="primary"
                        rightAddons={ (
                            <Amount
                                value={ increase }
                                currency={ currency }
                                color="primary"
                                type="decimal"
                                weight="bold"
                                view="title"
                            />
                        ) }
                    />
                </Padding>
            ) }
            <SuperListItem
                text={
                    (
                        <Flex gap="sm">
                            <div>{ `${status} ${text}, ${cashbox.title}` }</div>
                            { isOnline && (
                                <Tooltip
                                    trigger="click"
                                    position="top"
                                    content={ (
                                        <FlexColumns columns={ 1 } gr={ 8 }>
                                            { transaction.deleted && (
                                                <Typography.Text view="title" weight="bold">
                                                    { t('logs.removeTransaction') }
                                                </Typography.Text>
                                            ) }

                                            <Typography.Text view="title" weight="regular">
                                                { transaction.type === TransactionTypeEnum.PROCESSING
                                                    ? t('Платеж обрабатывается')
                                                    : t('Платеж получен') }
                                            </Typography.Text>

                                            { transaction.comment && (
                                                <Typography.Text view="title" weight="regular">
                                                    { transaction.comment }
                                                </Typography.Text>
                                            ) }

                                            <CopyButton
                                                fullText={ transaction.paymentId }
                                                text={ `${
                                                    transaction.provider ===
                                                    TransactionSourceEnum.PAYPAL
                                                        ? 'PayPal'
                                                        : 'Stripe'
                                                } payment Id: ${transaction.paymentId}` }
                                            />
                                        </FlexColumns>
                                    ) }
                                    dataTestId="test-id"
                                >
                                    { transaction.type === TransactionTypeEnum.PROCESSING ? (
                                        <ClockCircleS color="var(--color-graphic-accent)" />
                                    ) : (
                                        <CheckmarkCircleS color="var(--color-graphic-positive)" />
                                    ) }
                                </Tooltip>
                            ) }
                        </Flex>
                    ) as any as string
                }
                color={ transaction.amount < 0 ? 'negative' : 'primary' }
                disabled={ isOnline }
                hint={
                    formatDate(transaction.createdAt, { lang: i18n.language }) +
                    (transaction.comment && !isOnline ? `, ${transaction.comment}` : '')
                }
                leftAddons={
                    loading ? (
                        <Loader />
                    ) : (
                        <IconButton
                            onClick={ onRemove }
                            icon={ transaction.deleted ? UpdateS : CloseS }
                        />
                    )
                }
                rightAddons={ (
                    <Amount
                        value={ transaction.amount * -1 }
                        currency={ currency }
                        through={ transaction.deleted }
                        type="decimal"
                        color={ getTransactionStatusColor(transaction.amount, transaction.deleted) }
                        weight="bold"
                        view="title"
                    />
                ) }
            />
        </div>
    );
};
