import React from 'react';
import { useTranslation } from 'react-i18next';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CashboxRO, CashboxTypeEnum } from '@escapenavigator/types';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { ImagesGroup } from 'src/components/selects/base/images-group';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCertificates, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import Card from './card.webp';
import Paypal from './paypal.webp';
import Stripe from './stripe.webp';

const images = {
    paypal: Paypal,
    stripe: Stripe,
    bs: Card,
};

const getType = (type: CashboxTypeEnum) => {
    let res = 'paypalCashbox';

    if (type === CashboxTypeEnum.STRIPE) res = 'onlinePaymentsCashbox';
    if (type === CashboxTypeEnum.BS) res = 'bsCashbox';

    return res;
};

export const CashboxColumns = (): TableColumns<CashboxRO> => {
    const {
        current: {
            extra: { currency },
        },
    } = useCurrentUser();

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const certificates = useAppSelector(selectAllCertificates);

    const { t } = useTranslation();

    const routes: TableColumns<CashboxRO> = [
        {
            header: 'ID',
            accessor: 'title',
            row: {
                image: ({ row }) => images[row.type],
                subtitle: ({ row }) =>
                    (row.type === CashboxTypeEnum.BS
                        ? 'Выплаты за онлайн оплату'
                        : row.identificator),
            },
        },
        {
            header: t('Квесты'),
            accessor: 'createdAt',
            row: {
                title: ({ row }) => (
                    <ImagesGroup
                        images={ questrooms.filter((q) => q[getType(row.type)] === row.id) }
                    />
                ),
            },
        },
        {
            header: t('Сертификаты'),
            accessor: 'createdAt',
            row: {
                title: ({ row }) => (
                    <ImagesGroup
                        images={ certificates.filter((q) => q[getType(row.type)] === row.id) }
                    />
                ),
            },
        },
        {
            header: t('Наценка при онлайн-оплате'),
            accessor: 'increase',
            row: {
                title: ({ row }) => {
                    if (row.increaseType === PrepaymentTypeEnum.NO) return '-';
                    if (row.increaseType === PrepaymentTypeEnum.FIXED) return <Amount type="decimal" value={ row.increase } currency={ currency } />;

                    return <Amount type="decimal" value={ row.increase } currency="%" />;
                },
            },
        },
    ];

    return routes;
};
