// /* eslint-disable no-lone-blocks */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, FlexColumns, Input, ListAllert, Select, Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { UpdateCurrentBaseDto } from '@escapenavigator/types/dist/profile/update-current-base.dto';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { isRu } from '@escapenavigator/utils/dist/is-ru';
import { PageLoader } from 'src/components/page-loader';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { VerifyCompanyContainer } from '../components/container';

export const OrganizationForm: React.FC = () => {
    const { t } = useTranslation();

    const { profiles } = useApi();

    const { profile, setProfile } = useCurrentUser();

    const { finalizeCurrentFetch, finalizeCurrentLoading, finalizeCurrentError } = useApiMethod({
        api: profiles.finalizeCurrent,
        successCallback: ({ data }) => {
            setProfile(data);

            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100);
        },
    });

    if (finalizeCurrentLoading) return <PageLoader />;

    return (
        <BaseForm
            save={ (data) => finalizeCurrentFetch({ data }) }
            validate={ validateByDto(t) }
            initialValues={ serializeRecord(UpdateCurrentBaseDto, profile) }
            t={ t }
        >
            { ({
                values, handleChange, touched, errors, submitForm, setFieldValue,
            }) => (
                <VerifyCompanyContainer>
                    <FlexColumns columns={ 1 } gr={ 24 }>
                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                    { t('Компания') }
                                </Typography.Text>

                                <FlexColumns columns={ 1 } gr={ 16 }>
                                    <Input
                                        label={ t('Название для клиентов') }
                                        value={ values.title }
                                        name="title"
                                        aria-autocomplete="none"
                                        onChange={ handleChange('title') }
                                        error={ touched.title && errors.title }
                                        block={ true }
                                    />
                                    <Input
                                        label={ t('Юридическое название') }
                                        value={ values.legalTitle }
                                        aria-autocomplete="none"
                                        name="legalTitle"
                                        onChange={ handleChange('legalTitle') }
                                        error={ touched.legalTitle && errors.legalTitle }
                                        block={ true }
                                    />

                                    <Input
                                        label={ t('Телефон для посетителей') }
                                        value={ values.phoneForCustomers }
                                        onChange={ handleChange('phoneForCustomers') }
                                        error={
                                            touched.phoneForCustomers && errors.phoneForCustomers
                                        }
                                        block={ true }
                                    />
                                    <Input
                                        label="Email"
                                        value={ values.mainEmail }
                                        onChange={ handleChange('mainEmail') }
                                        error={ touched.mainEmail && errors.mainEmail }
                                        block={ true }
                                    />
                                </FlexColumns>
                            </FlexColumns>
                        </FlexColumns>
                        { !isRu() && (
                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                    { t('language') }
                                </Typography.Text>

                                <FlexColumns columns={ 1 } gr={ 16 }>
                                    <Select
                                        dataTestId="languages"
                                        multiple={ true }
                                        block={ true }
                                        label={ t('component.orderLanguages') }
                                        options={ languageOptions(Object.values(Languages)) }
                                        selected={ values.availableLanguages }
                                        error={
                                            touched.availableLanguages &&
                                            (errors.availableLanguages as string)
                                        }
                                        allowUnselect={ true }
                                        onChange={ ({ selectedMultiple }) => {
                                            const selected = selectedMultiple.map((i) => i.key);

                                            setFieldValue('availableLanguages', selected);

                                            if (!selected.includes(values.language)) {
                                                setFieldValue('language', null);
                                            }
                                        } }
                                        optionsListWidth="field"
                                    />

                                    <Select
                                        block={ true }
                                        optionsListWidth="field"
                                        label={ t('component.defaultLanguage') }
                                        error={ touched.language && errors.language }
                                        options={ languageOptions(
                                            Object.values(Languages).filter((l) =>
                                                values.availableLanguages.includes(l)),
                                        ) }
                                        selected={ values.language }
                                        onChange={ ({ selected }) =>
                                            setFieldValue('language', selected.key) }
                                    />
                                </FlexColumns>
                            </FlexColumns>
                        ) }
                    </FlexColumns>

                    { finalizeCurrentError?.message && (
                        <ListAllert view="negative" text={ finalizeCurrentError?.message } />
                    ) }

                    <Button view="primary" onClick={ submitForm }>
                        { t('verifyCompany.steps.next') }
                    </Button>
                </VerifyCompanyContainer>
            ) }
        </BaseForm>
    );
};
