/* eslint-disable no-alert */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditS, PhotoM } from '@alphakits/icons';
import {
    Button,
    Flex,
    FlexColumns,
    Image,
    ImageUploader,
    Input,
    ListAllert,
    Select,
    showSuccess,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { UpdateCurrentDto } from '@escapenavigator/types/dist/profile/update-current.dto';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { isRu } from '@escapenavigator/utils/dist/is-ru';
import { Section } from 'src/components/layout/section';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { useOnboarding } from 'src/providers/ongoarding/context';

export const ProfilePage: React.FC = () => {
    const { t } = useTranslation();
    const { profiles, uploads } = useApi();
    const { profile, setProfile } = useCurrentUser();
    const { refetchProgress } = useOnboarding();

    const { updateCurrentError, updateCurrentFetch, updateCurrentLoading } = useApiMethod({
        api: profiles.updateCurrent,
        successCallback: ({ data }) => {
            setProfile(data);
            refetchProgress();
            showSuccess('Success');
        },
    });

    const submit = async (data: UpdateCurrentDto) => {
        updateCurrentFetch({ data });
    };

    // initialValues={ serializeRecord(
    //     UpdateCustomizationDto,
    //     getCustomizationData || {},
    // ) }
    // validate={ validateByDto(t) }
    // enableReinitialize={ true }
    // save={ (values) => updateCustomizationFetch(values) }
    // loading={ getCustomizationLoading }
    // t={ t }

    return (
        <BaseForm
            save={ submit }
            initialValues={ serializeRecord(UpdateCurrentDto, profile) }
            validate={ validateByDto(t) }
            loading={ updateCurrentLoading }
            enableReinitialize={ true }
            t={ t }
        >
            { ({
                values, handleChange, touched, errors, setFieldValue, submitForm,
            }) => (
                <Section>
                    <FlexColumns columns={ 1 } gr={ 24 }>
                        <Flex gap="md" justify="start">
                            <ImageUploader
                                title="uploader"
                                value={ [values.logo] }
                                onUpdate={ ([value]) => setFieldValue('logo', value) }
                                multiple={ false }
                                uploadRequest={ uploads.uploadImage }
                                type={ ImageTypeEnum.PROFILE_LOGO }
                            >
                                { ({ loading }) => (
                                    <Image
                                        src={ values.logo || '' }
                                        icon={ values.logo ? undefined : <PhotoM /> }
                                        loading={ loading }
                                        view="default"
                                        size="l"
                                        addon={ {
                                            color: 'secondary',
                                            filled: true,
                                            icon: <EditS />,
                                        } }
                                    />
                                ) }
                            </ImageUploader>

                            <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                Logo
                            </Typography.Text>
                        </Flex>
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                { t('Компания') }
                            </Typography.Text>

                            <FlexColumns columns={ 2 } gr={ 16 }>
                                <Input
                                    label={ t('Название для клиентов') }
                                    value={ values.title }
                                    onChange={ handleChange('title') }
                                    error={ touched.title && errors.title }
                                    block={ true }
                                />
                                <Input
                                    label={ t('Юридическое название') }
                                    value={ values.legalTitle }
                                    onChange={ handleChange('legalTitle') }
                                    error={ touched.legalTitle && errors.legalTitle }
                                    block={ true }
                                />
                            </FlexColumns>

                            <Input
                                label={ t('Agreement link') }
                                value={ values.agreementLink }
                                onChange={ handleChange('agreementLink') }
                                error={ touched.agreementLink && errors.agreementLink }
                                block={ true }
                            />
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                { t('tables.contact') }
                            </Typography.Text>
                            <FlexColumns columns={ 2 } gr={ 16 }>
                                <Input
                                    label={ t('Телефон для посетителей') }
                                    value={ values.phoneForCustomers }
                                    onChange={ handleChange('phoneForCustomers') }
                                    error={ touched.phoneForCustomers && errors.phoneForCustomers }
                                    block={ true }
                                />
                                <Input
                                    label="Website"
                                    value={ values.site }
                                    onChange={ handleChange('site') }
                                    error={ touched.site && errors.site }
                                    required={ true }
                                    block={ true }
                                />
                                <Input
                                    label="Email"
                                    value={ values.mainEmail }
                                    onChange={ handleChange('mainEmail') }
                                    error={ touched.mainEmail && errors.mainEmail }
                                    block={ true }
                                />
                            </FlexColumns>
                        </FlexColumns>

                        { !isRu() && (
                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                    { t('language') }
                                </Typography.Text>
                                <FlexColumns columns={ 2 } gr={ 16 }>
                                    <Select
                                        dataTestId="languages"
                                        multiple={ true }
                                        block={ true }
                                        label={ t('component.orderLanguages') }
                                        options={ languageOptions(Object.values(Languages)) }
                                        selected={ values.availableLanguages }
                                        error={
                                            touched.availableLanguages &&
                                            (errors.availableLanguages as string)
                                        }
                                        allowUnselect={ true }
                                        onChange={ ({ selectedMultiple }) => {
                                            const selected = selectedMultiple.map((i) => i.key);

                                            setFieldValue('availableLanguages', selected);

                                            if (!selected.includes(values.language)) {
                                                setFieldValue('language', null);
                                            }
                                        } }
                                        optionsListWidth="field"
                                    />

                                    <Select
                                        block={ true }
                                        optionsListWidth="field"
                                        label={ t('component.defaultLanguage') }
                                        error={ touched.language && errors.language }
                                        options={ languageOptions(
                                            Object.values(Languages).filter((l) =>
                                                values.availableLanguages.includes(l)),
                                        ) }
                                        selected={ values.language }
                                        onChange={ ({ selected }) =>
                                            setFieldValue('language', selected.key) }
                                    />
                                </FlexColumns>
                            </FlexColumns>
                        ) }

                        { updateCurrentError?.message && (
                            <ListAllert view="negative" text={ updateCurrentError.message } />
                        ) }

                        <div>
                            <Button
                                onClick={ submitForm }
                                size="s"
                                view="primary"
                                loading={ updateCurrentLoading }
                            >
                                { t('save') }
                            </Button>
                        </div>
                    </FlexColumns>
                </Section>
            ) }
        </BaseForm>
    );
};
