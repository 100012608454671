import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AllertMarkM, CheckmarkCircleFillM, ClockCircleM, GearM, UpdateS,
} from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, ModalContext, showError, Status,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { IntegrationCard } from 'src/components/integration-card';
import { FbPixelModal } from 'src/modals/fb-pixel';
import { GaModal } from 'src/modals/ga';
import { GoogleCalendarCreateModal } from 'src/modals/google-calendar-modal/create';
import { GoogleCalendarEditModal } from 'src/modals/google-calendar-modal/edit';
import { MailchimpModal } from 'src/modals/mailchimp';
import { SmtpManagerModal } from 'src/modals/smtp-modal/edit';
import { WebhooksManagerModal } from 'src/modals/webhook-modal/edit';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

export const IntegrationsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { mailchimpApi } = useApi();
    const { t } = useTranslation();
    const { profile } = useCurrentUser();

    /**
     * Mailchimp
     */
    const mailchimpAuthLink = useApiMethod({
        api: mailchimpApi.authLink,
        successCallback: ({ data }) => {
            window.location.href = data;
        },
    });

    const mailchimpGet = useApiMethod({
        api: mailchimpApi.get,
    });

    const mailchimCheckCode = useApiMethod({
        api: mailchimpApi.checkCode,
        successCallback: () => mailchimpGet.getFetch(undefined),
    });

    const mailChimpActive = mailchimpGet.getData?.listId;
    /**
     *
     */

    /**
     * GA
     */
    const gaEnabled = profile.gaEnabled && profile.ga4Id;
    const fbEnabled = profile.fbPixelEnabled && profile.fbPixelId;

    const gaDescription = useMemo(() => {
        if (!gaEnabled) return t('integrations:analDescription');

        const res = [profile.ga4Id];

        if (profile.gaCoversationId) {
            res.push(`${profile.gaCoversationId}/${profile.gaCoversationLabel}`);
        }

        return t('googleAnal.description', { value: res.join(', ') });
    }, [profile, gaEnabled, t]);
    /**
     *
     */

    /**
     * Calendar
     */
    const { googleCalendarsApi, webhooksApi } = useApi();

    const getAllCalendars = useApiMethod({
        api: googleCalendarsApi.getAll,
    });

    const getAllWebhooks = useApiMethod({
        api: webhooksApi.query,
    });

    const getCalendarLink = useApiMethod({
        api: googleCalendarsApi.getVerificationLink,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => {
            window.location.href = data;
        },
    });

    const calendarsActive = !!getAllCalendars.getAllData?.length;
    const webhooksActive = !!getAllWebhooks.queryData?.length;

    const calendarsOnClick = () =>
        (calendarsActive
            ? openModal(
                GoogleCalendarEditModal,
                'm',
            )({
                calendars: getAllCalendars.getAllData,
                fetchLink: () => getCalendarLink.getVerificationLinkFetch(undefined),
                cb: () => getAllCalendars.getAllFetch(undefined),
            })
            : getCalendarLink.getVerificationLinkFetch(undefined));

    useEffect(() => {
        getAllCalendars.getAllFetch(undefined);
        mailchimpGet.getFetch(undefined);
        getAllWebhooks.queryFetch(undefined);

        const query = new URLSearchParams(window.location.search);

        const code = query.get('code');
        const mailchimp = query.get('mailchimp');

        if (mailchimp && code) {
            mailchimCheckCode.checkCodeFetch({ code, profileId: profile.id });
        }

        if (code && !mailchimp) {
            openModal(GoogleCalendarCreateModal)({
                clientSecret: code,
                cb: () => getAllCalendars.getAllFetch(undefined),
            });
        }
        window.history.replaceState({}, null, window.location.href.split('?')[0]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     *
     */

    const cards = [
        {
            title: 'Google calendar',
            onClick: calendarsOnClick,
            status: calendarsActive ? ('enabled' as const) : ('none' as const),
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/gc.png',
            description: t('integrations:calendarDescription'),
        },
        {
            title: 'Google analytics',
            onClick: () => openModal(GaModal)(),
            status: gaEnabled ? ('enabled' as const) : ('none' as const),
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/ga.png',
            description: gaDescription,
        },
        {
            title: 'MailChimp',
            onClick: mailChimpActive
                ? () =>
                    openModal(MailchimpModal)({
                        record: mailchimpGet.getData,
                        cb: () => mailchimpGet.getFetch(undefined),
                    })
                : () => mailchimpAuthLink.authLinkFetch(undefined),
            status: mailChimpActive ? ('enabled' as const) : ('none' as const),
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/mailchimp.png',
            description: t('integrations:mailchimpDescription'),
        },
        {
            title: 'SMTP',
            onClick: () => openModal(SmtpManagerModal, 'm')(),
            status: 'default' as const,
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/smtp.png',
            description: t('smtpDescription'),
        },

        {
            title: 'Facebook Pixel',
            onClick: () => openModal(FbPixelModal)(),
            status: fbEnabled ? ('enabled' as const) : ('none' as const),
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/fbpixel.png',
            description: t('integrations:fbDescription'),
        },
        {
            title: 'Webhooks',
            onClick: () => openModal(WebhooksManagerModal, 'm')(),
            status: webhooksActive ? ('enabled' as const) : ('none' as const),
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/webhook.png',
            description: t('integrations:webhooksActive'),
        },
        {
            title: 'Zapier',
            status: 'soon' as const,
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/zapier.png',
            description: t('integrations:zapier'),
        },
        {
            title: 'Whatsapp',
            status: 'soon' as const,
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/whatsap.png',
            description: t('integrations:whatsapDescription'),
        },
        {
            title: 'Telegram',
            status: 'soon' as const,
            image: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/integrations/tg.png',
            description: t('integrations:telegramDescription'),
        },
    ];

    const getChilren = (status, onClick?: () => any) => (
        <React.Fragment>
            { status === 'none' && (
                <Button onClick={ onClick } view="outlined" size="xs">
                    Connect
                </Button>
            ) }

            { status === 'default' && (
                <Button onClick={ onClick } view="outlined" size="xs">
                    Settings
                </Button>
            ) }

            { status === 'disabled' && (
                <Button onClick={ onClick } view="outlined" size="xs">
                    Disabled
                </Button>
            ) }

            { status === 'soon' && (
                <Status color="grey" view="soft" size="m">
                    <ClockCircleM /> Comming soon
                </Status>
            ) }

            { status === 'problem' && (
                <React.Fragment>
                    <Status color="orange" view="soft" size="m">
                        <AllertMarkM /> Need resync
                    </Status>

                    <Button onClick={ onClick } view="outlined" size="xs" leftAddons={ <UpdateS /> } />
                </React.Fragment>
            ) }

            { status === 'enabled' && (
                <React.Fragment>
                    <Status color="green" view="soft" size="m">
                        <CheckmarkCircleFillM /> Active
                    </Status>

                    <Button onClick={ onClick } view="ghost" size="xs" leftAddons={ <GearM /> } />
                </React.Fragment>
            ) }
        </React.Fragment>
    );

    const loading =
        mailchimpAuthLink.authLinkLoading ||
        mailchimpGet.getLoading ||
        getAllCalendars.getAllLoading ||
        mailchimCheckCode.checkCodeLoading ||
        getCalendarLink.getVerificationLinkLoading;

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <Flex wrap={ true } gap="l" justify="start">
                { cards.map((card) => (
                    <IntegrationCard
                        key={ card.title }
                        title={ card.title }
                        loading={ loading }
                        isSoon={ card.status === 'soon' }
                        description={ card.description }
                        image={ card.image }
                    >
                        { getChilren(card.status, card.onClick) }
                    </IntegrationCard>
                )) }
            </Flex>
        </FlexColumns>
    );
};
