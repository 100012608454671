import React from 'react';
import {
    AmountInput, Checkbox, FlexColumns, Select, Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { SlotTemplateElement } from '@escapenavigator/types/dist/slot-template/slot-template-element';
import { SlotAutocompliteFormDto } from '@escapenavigator/types/dist/slot/slot-autocomplite-form.dto';
import {
    convertHHMMToMinutes,
    convertMinutesToHHMM,
    serializeRecord,
    validateByDto,
} from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { TariffSelect } from 'src/components/tariff-select';
import { TimeInput } from 'src/components/time-input';
import { getOptions } from 'src/modals/questroom/options';
import { selectAllTariffs } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { useRulesApi } from 'src/store/hooks/use-rules';

import { BookingRule } from '../booking-rules';

import { DaysOfWeek } from './days-of-week';
import { buildTemplate, getOptionForTimeSelect } from './utils';

type Props = {
    close: () => void;
    t: TFunction;
    submit: (values: SlotTemplateElement[]) => void;
    questroomTime: number;
    initialStart?: string;
    initialDay?: number;
};

export const Autocomplite: React.FC<Props> = ({
    close,
    t,
    submit,
    questroomTime,
    initialStart,
    initialDay,
}) => {
    const tariffs = useAppSelector(selectAllTariffs) || [];

    const {
        rules, loading, updating, createRule,
    } = useRulesApi();

    const handleSubmut = async (values: SlotAutocompliteFormDto) => {
        let { ruleId } = values;

        if (!ruleId) {
            const rule = await createRule({
                title: values.title,
                minHoursForBooking: values.minHoursForBooking,
                blockingHours: values.blockingHours,
                openSlotWhenUserInCalendar: values.openSlotWhenUserInCalendar,
                minHoursForFreeCanceling: values.minHoursForFreeCanceling,
                prepayForTourists: values.prepayForTourists,
                prepayment: values.prepayment,
                prepaymentType: values.prepaymentType,
                cancelationRule: values.cancelationRule,
                cancelationAmount: values.cancelationAmount,
                saved: values.saved,
            });

            if (!rule) return;

            ruleId = rule.id;
        }

        const template = buildTemplate({ ...values, ruleId }, questroomTime || 60);

        submit(template);
        close();
    };

    return (
        <RestForm
            title={ t('Автозаполнение') }
            loading={ loading || updating }
            save={ handleSubmut }
            validate={ validateByDto(t) }
            close={ close }
            t={ t }
            initialValues={ serializeRecord(SlotAutocompliteFormDto, {
                ruleId: rules?.[0]?.id,
                tariffId: tariffs[0]?.id,
                start: initialStart,
                end: initialStart
                    ? convertMinutesToHHMM(convertHHMMToMinutes(initialStart) + questroomTime)
                    : '22:00',
                days: initialDay === undefined ? [0, 1, 2, 3, 4, 5, 6] : [initialDay],
                title: rules?.length ? null : 'Standart',
            }) }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 32 }>
                    <FlexColumns columns={ 1 } gr={ 20 }>
                        <Typography.Title tag="div" view="xxsmall" weight="bold">
                            { t('Расписание') }
                        </Typography.Title>

                        <DaysOfWeek
                            selected={ values.days || [] }
                            onChange={ (d) => setFieldValue('days', d) }
                        />

                        <FlexColumns columns={ 2 } gr={ 16 }>
                            <TimeInput
                                dataTestId="start"
                                label={ t('Начало первого сеанса') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ key }) => setFieldValue('start', key) }
                                selected={ values.start }
                                error={ touched.start && errors.start }
                                block={ true }
                            />
                            <TimeInput
                                dataTestId="end"
                                label={ t('Начало последнего сеанса') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ key }) => setFieldValue('end', key) }
                                selected={ values.end }
                                error={ touched.end && errors.end }
                                block={ true }
                            />
                        </FlexColumns>

                        <Select
                            dataTestId="break"
                            label={ t('component.breakForGame') }
                            selected={ values.breakInterval }
                            options={ getOptions(0, 120, 'min', 5) }
                            onChange={ ({ selected }) =>
                                setFieldValue('breakInterval', selected?.key) }
                            block={ true }
                        />

                        <TariffSelect
                            value={ values.tariffId }
                            onChange={ (id) => setFieldValue('tariffId', id) }
                            error={ touched.tariffId && errors.tariffId }
                        />

                        <AmountInput
                            label={ t('Скидка') }
                            value={ values.discount }
                            suffix={ '%' as any }
                            onChange={ (e, { value }) => setFieldValue('discount', value) }
                            block={ true }
                            type="decimal"
                        />

                        <Checkbox
                            label={ t('Available by phone only') }
                            checked={ values.onlyPhone }
                            onChange={ (e, { checked }) => setFieldValue('onlyPhone', checked) }
                            block={ true }
                        />
                    </FlexColumns>

                    <BookingRule
                        values={ values }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        touched={ touched }
                        rules={ rules || [] }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
