import React, { ReactNode } from 'react';
import {
    NextS, PresentS, TicketS, WalletS,
} from '@alphakits/icons';
import {
    Amount, BgColors, Flex, formatDate, Image,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { BalanceItemTypeEnum } from '@escapenavigator/types/dist/balance/balance-item-type.enum';
import { BalanceItemRO } from '@escapenavigator/types/dist/balance/balance-item.ro';
import escapeNavigator from 'src/components/layout/logo/escapeNavigator.png';

export const sourceIcons: Record<BalanceItemTypeEnum, ReactNode | null> = {
    aggregator: null,
    bonus: <PresentS />,
    charge: <TicketS />,
    hand: null,
    payment: <WalletS />,
};

export const sourceImages: Record<BalanceItemTypeEnum, string | null> = {
    aggregator: escapeNavigator,
    bonus: null,
    charge: null,
    hand: null,
    payment: null,
};

const getTtitle = ({
    type,
    amount,
}: BalanceItemRO): {
    color: BgColors;
    title: string;
} => {
    if (type === BalanceItemTypeEnum.BONUS) {
        return {
            color: 'positive',
            title: 'Начислены бонусы',
        };
    }
    if (type === BalanceItemTypeEnum.CHARGE && amount > 0) {
        return {
            color: 'attention',
            title: 'Возврат комиссии за бронирование',
        };
    }

    if (type === BalanceItemTypeEnum.HAND && amount > 0) {
        return {
            color: 'attention',
            title: 'Ручное начисление',
        };
    }

    if (type === BalanceItemTypeEnum.HAND && amount < 0) {
        return {
            color: 'attention',
            title: 'Ручное списание',
        };
    }

    if (type === BalanceItemTypeEnum.AGGREGATOR && amount > 0) {
        return {
            color: 'attention',
            title: 'Возврат комиссии агрегатора',
        };
    }

    if (type === BalanceItemTypeEnum.AGGREGATOR && amount < 0) {
        return {
            color: 'positive',
            title: 'Комиссия агрегатора',
        };
    }

    if (type === BalanceItemTypeEnum.PAYMENT) {
        return {
            color: 'positive',
            title: 'Пополнение счета',
        };
    }

    return {
        color: 'negative',
        title: 'Комиссия за бронирование',
    };
};

export const columns = (i18n): TableColumns<BalanceItemRO> => [
    {
        header: '',
        accessor: 'type',
        width: '0px !importnat',
        row: {
            title: ({ row }) => (
                <Image
                    view="ellipse"
                    size="s"
                    src={ sourceImages[row.type] }
                    bgColor={ getTtitle(row).color }
                    color="primary"
                    icon={ sourceIcons[row.type] }
                />
            ),
        },
    },
    {
        header: 'Type',
        accessor: 'id',
        row: {
            title: ({ row }) => getTtitle(row).title,
        },
    },
    {
        header: 'Type',
        accessor: 'createdAt',
        row: {
            title: ({ row }) =>
                formatDate(row.createdAt, { format: 'dd MMMM, HH:mm', lang: i18n.language }),
        },
    },
    {
        header: 'Amount',
        accessor: 'amount',
        row: {
            title: ({ row }) => (
                <Amount
                    value={ row.amount }
                    weight="bold"
                    type="decimal"
                    color={ row.amount < 0 ? 'negative' : 'positive' }
                    currency="RUB"
                />
            ),
        },
    },
    {
        header: 'Баланс',
        accessor: 'oldTotal',
        row: {
            title: ({ row }) => (
                <Flex gap="sm" justify="start">
                    <Amount value={ row.oldTotal } color="secondary" type="decimal" currency="RUB" />

                    <NextS />

                    <Amount type="decimal" value={ row.amount + row.oldTotal } currency="RUB" />
                </Flex>
            ),
        },
    },
];
