import React from 'react';
import { FlexColumns } from '@alphakits/ui';
import { isRu } from '@escapenavigator/utils/dist/is-ru';

import { SubscriptionPageCOM } from './com';
import { SubscriptionPageRu } from './ru';

export const SubscriptionPage: React.FC = () => {
    if (isRu()) {
        return <SubscriptionPageRu />;
    }

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            { /* <SubscriptionPageRu /> */ }

            <SubscriptionPageCOM />
        </FlexColumns>
    );
};
