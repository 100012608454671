import React, { useState } from 'react';
import { SecurePaymentM } from '@alphakits/icons';
import {
    AmountInput, Button, FlexColumns, showError, showSuccess,
} from '@alphakits/ui/dist';
import { useCurrentUser } from 'src/providers/current-user/context';

import { Section } from '../../../../components/layout/section';

export const CludPaymentsForm: React.FC = () => {
    const {
        current: {
            user: { email, profileId },
        },
        profile: { currency },
    } = useCurrentUser();

    const [amount, setAmount] = useState(5000);

    const pay = () => {
        if (!amount) return null;

        const widget = new (window as any).cp.CloudPayments();

        return widget.pay(
            'charge',
            {
                publicId: process.env.REACT_APP_CLOUD_PAYMENT_PUB,
                description: 'Пополнение счета в личном кабинете Escape Navigator',
                amount,
                currency: 'RUB',
                accountId: email,
                data: {
                    profileId,
                    type: 'topup',
                },
            },
            {
                onSuccess() {
                    showSuccess(
                        'Оплата успешно завершена. Средства будут зачислены на ваш счет в течение нескольких минут.',
                    );
                },
                onFail(reason) {
                    showError(reason, 'Не удалось произвести оплату');
                },
                // onComplete(paymentResult, options) {},
            },
        );
    };

    return (
        <Section title="Пополнение счета">
            <FlexColumns columns={ 4 }>
                <AmountInput
                    label="Сумма"
                    value={ amount }
                    suffix={ currency }
                    type="default"
                    onChange={ (_, { value }) => setAmount(value) }
                    required={ true }
                    block={ true }
                />

                <Button
                    disabled={ !amount }
                    leftAddons={ <SecurePaymentM /> }
                    view="primary"
                    size="m"
                    onClick={ pay }
                >
                    Пополнить
                </Button>
            </FlexColumns>
        </Section>
    );
};
