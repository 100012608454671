import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput,
    CalendarInput,
    Checkbox,
    CheckboxGroups,
    Collapse,
    Divider,
    FlexColumns,
    Input,
    Padding,
    Select,
    SuperListHeader,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreatePromocodeDto } from '@escapenavigator/types/dist/promocode/create-promocode.dto';
import { PromocodeTypeEnum } from '@escapenavigator/types/dist/promocode/emun/promocode-type.enum';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TimeInput } from 'src/components/time-input';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { getOptions } from '../questroom/options';
import { DaysOfWeek } from '../slots/autocomplite/days-of-week';
import { getOptionForTimeSelect } from '../slots/autocomplite/utils';

type Props = {
    recordId?: number;
    close: () => void;
    cb: (promocode: PromocodeRO) => void;
};

export const PromocodeModal: React.FC<Props> = ({ recordId, close, cb }) => {
    const { promocodes } = useApi();
    const { t, i18n } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();
    const questrooms = useAppSelector(selectAllQuestrooms({})) || [];
    const locations = useAppSelector(selectAllLocations) || [];

    const { record, loading, error } = useGetOne(recordId, promocodes.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: promocodes.remove,
        saveRequest: recordId ? promocodes.update : promocodes.create,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ record.code || t('Промокод') }
            initialValues={ serializeRecord(
                CreatePromocodeDto,
                record?.id
                    ? record
                    : {
                        availableDays: [0, 1, 2, 3, 4, 5, 6],
                        availableFrom: '00:00',
                        availableTo: '23:55',
                    },
            ) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="code"
                        label={ t('Промокод') }
                        value={ values.code }
                        error={ touched.code && errors.code }
                        onChange={ handleChange('code') }
                        block={ true }
                        hint={ t('Описание кода промокода') }
                    />

                    <Textarea
                        dataTestId="description"
                        label={ t('tables.description') }
                        value={ values.description }
                        error={ touched.description && errors.description }
                        onChange={ handleChange('description') }
                        hint={ t('Описание промокода') }
                        block={ true }
                        maxLength={ 500 }
                        counter={ true }
                        required={ true }
                    />

                    <Select
                        dataTestId="type"
                        disabled={ !!record?.id }
                        selected={ values.type }
                        label={ t('tables.type') }
                        onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                        options={ enumToOptions(PromocodeTypeEnum, t, 'promocodes') }
                        error={ touched.type && errors.type }
                        required={ true }
                        block={ true }
                        optionsListWidth="field"
                    />

                    <AmountInput
                        dataTestId="discount"
                        disabled={ !!record?.id }
                        label={ t('Скидка') }
                        value={ values.discount }
                        error={ touched.discount && errors.discount }
                        suffix={ (PromocodeTypeEnum.FIXED === values.type ? currency : '%') as any }
                        onChange={ (e, { value }) => setFieldValue('discount', value) }
                        block={ true }
                        type="decimal"
                    />

                    <Checkbox
                        label={ t('promocodeAvailableForBookings') }
                        checked={ values.availableForBookings }
                        onChange={ (e, { checked }) =>
                            setFieldValue('availableForBookings', checked) }
                        block={ true }
                    />

                    <Checkbox
                        label={ t('promocodeAwailableForCertificates') }
                        checked={ values.availableForCertificates }
                        onChange={ (e, { checked }) =>
                            setFieldValue('availableForCertificates', checked) }
                        block={ true }
                    />

                    <Checkbox
                        dataTestId="multiple"
                        label={ t('Можно использовать много раз') }
                        checked={ values.multiple }
                        onChange={ (e, { checked }) => setFieldValue('multiple', checked) }
                        block={ true }
                    />
                    { values.multiple && (
                        <AmountInput
                            dataTestId="availableApplyings"
                            suffix={ '' as any }
                            label={ t('Количество применений') }
                            hint={ t('Количество применений хинт') }
                            value={ values.availableApplyings }
                            error={ touched.availableApplyings && errors.availableApplyings }
                            onChange={ (e, { value }) => setFieldValue('availableApplyings', value) }
                            block={ true }
                        />
                    ) }

                    <Divider size="m" />

                    <FlexColumns columns={ 2 } gc={ 16 }>
                        <CalendarInput
                            dataTestId="validFrom"
                            label={ t('Действует с') }
                            value={ values.validFrom }
                            onChange={ (e, { value }) => setFieldValue('validFrom', value) }
                            block={ true }
                            lang={ i18n.language }
                        />

                        <CalendarInput
                            dataTestId="validTo"
                            label={ t('to') }
                            value={ values.validTo }
                            onChange={ (e, { value }) => setFieldValue('validTo', value) }
                            block={ true }
                            lang={ i18n.language }
                        />
                    </FlexColumns>

                    { values.availableForBookings && (
                        <Collapse
                            collapsedLabel={ t('Продвинутые настройки') }
                            expandedLabel={ t('Скрыть') }
                            dataTestId="minHoursForBookingCollapse"
                        >
                            <br />
                            <FlexColumns columns={ 1 } gr={ 20 }>
                                <FlexColumns columns={ 1 } gr={ 12 }>
                                    <Typography.Text tag="div" view="title" weight="medium">
                                        { t('Действует на игры на определеннные даты') }
                                    </Typography.Text>

                                    <FlexColumns columns={ 2 } gc={ 16 }>
                                        <CalendarInput
                                            dataTestId="forOrdersFrom"
                                            label={ t('from') }
                                            value={ values.forOrdersFrom }
                                            onChange={ (e, { value }) =>
                                                setFieldValue('forOrdersFrom', value) }
                                            block={ true }
                                            lang={ i18n.language }
                                        />

                                        <CalendarInput
                                            dataTestId="forOrdersTo"
                                            label={ t('to') }
                                            value={ values.forOrdersTo }
                                            onChange={ (e, { value }) =>
                                                setFieldValue('forOrdersTo', value) }
                                            block={ true }
                                            lang={ i18n.language }
                                        />
                                    </FlexColumns>
                                </FlexColumns>

                                <FlexColumns columns={ 1 } gr={ 12 }>
                                    <Typography.Text tag="div" view="title" weight="medium">
                                        { t('Действует на игры на определенные дни или часы') }
                                    </Typography.Text>

                                    <DaysOfWeek
                                        selected={ values.availableDays }
                                        onChange={ (days) => setFieldValue('availableDays', days) }
                                    />

                                    <FlexColumns columns={ 2 } gr={ 16 }>
                                        <TimeInput
                                            dataTestId="start"
                                            label={ t('from') }
                                            options={ getOptionForTimeSelect() }
                                            onChange={ ({ key }) =>
                                                setFieldValue('availableFrom', key) }
                                            selected={ values.availableFrom }
                                            block={ true }
                                        />
                                        <TimeInput
                                            dataTestId="end"
                                            label={ t('to') }
                                            options={ getOptionForTimeSelect() }
                                            onChange={ ({ key }) =>
                                                setFieldValue('availableTo', key) }
                                            selected={ values.availableTo }
                                            block={ true }
                                        />
                                    </FlexColumns>
                                </FlexColumns>

                                <FlexColumns columns={ 1 } gr={ 12 }>
                                    <Typography.Text tag="div" view="title" weight="medium">
                                        { t('Действует на игры с определеннным колвом чел') }
                                    </Typography.Text>

                                    <FlexColumns columns={ 2 } gr={ 16 } gc={ 20 }>
                                        <Select
                                            dataTestId="minPlayers"
                                            label={ t('component.playersMin') }
                                            options={ getOptions(1, 20) }
                                            selected={ values.minPlayers }
                                            error={ touched.minPlayers && errors.minPlayers }
                                            onChange={ ({ selected }) =>
                                                setFieldValue('minPlayers', selected?.key) }
                                            block={ true }
                                        />

                                        <Select
                                            dataTestId="maxPlayers"
                                            label={ t('component.playersMax') }
                                            error={ touched.maxPlayers && errors.maxPlayers }
                                            options={ getOptions(1, 50) }
                                            selected={ values.maxPlayers }
                                            onChange={ ({ selected }) =>
                                                setFieldValue('maxPlayers', selected?.key) }
                                            block={ true }
                                        />
                                    </FlexColumns>
                                </FlexColumns>
                            </FlexColumns>
                        </Collapse>
                    ) }

                    <Divider size="m" />

                    <Checkbox
                        dataTestId="canUseWithOtherCertificates"
                        label={ t('Можно использовать с другими сертификатами') }
                        checked={ values.canUseWithOtherCertificates }
                        onChange={ (e, { checked }) =>
                            setFieldValue('canUseWithOtherCertificates', checked) }
                        block={ true }
                    />

                    <Checkbox
                        dataTestId="canUseWithOtherPromocodes"
                        label={ t('Можно использовать с другими промокодами') }
                        checked={ values.canUseWithOtherPromocodes }
                        onChange={ (e, { checked }) =>
                            setFieldValue('canUseWithOtherPromocodes', checked) }
                        block={ true }
                    />

                    <Checkbox
                        dataTestId="inside"
                        label={ t('Не доступен для применения в виджетах') }
                        checked={ values.inside }
                        onChange={ (e, { checked }) => setFieldValue('inside', checked) }
                        block={ true }
                    />

                    { values.availableForBookings && (
                        <React.Fragment>
                            <SuperListHeader text={ t('tables.quest') } />

                            <Checkbox
                                dataTestId="allQuestrooms"
                                label={ t('component.forAllQuestrooms') }
                                checked={ values.allQuestrooms }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('allQuestrooms', checked) }
                            />

                            { !values.allQuestrooms && (
                                <React.Fragment>
                                    <Padding padding="8px 0" />
                                    { touched.allQuestrooms && errors.questroomsIds && (
                                        <Typography.Text
                                            color="negative"
                                            view="title"
                                            weight="medium"
                                        >
                                            { errors.questroomsIds }
                                        </Typography.Text>
                                    ) }
                                    <CheckboxGroups
                                        parents={ locations }
                                        childs={ questrooms }
                                        selected={ values.questroomsIds }
                                        onChange={ (value) => setFieldValue('questroomsIds', value) }
                                    />
                                </React.Fragment>
                            ) }
                        </React.Fragment>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
