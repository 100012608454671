import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@alphakits/ui';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const BalanceDetalization: React.FC = () => {
    const { profiles } = useApi();
    const { i18n } = useTranslation();

    return (
        <Table.TableComponent
            columns={ columns(i18n) }
            searchPlaceholder="Serach"
            language="en"
            promise={ profiles.queryBalanceItems }
        />
    );
};
