/* eslint-disable @typescript-eslint/no-explicit-any */

import { convertHHMMToMinutes, convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';

import { SerializedTableSlot, TableSlot, View } from '..';

import { getPx } from './get-px';

type Res<S> = {
    range: [number, number];
    serializedSlots: Array<SerializedTableSlot<S>>;
    nowPosition: number;
    latestSlot: number;
};

const round = (num: number) => Math.ceil(num / 60) * 60;

export const getTimetableSlotsAndRange = <S>(
    slots: Array<TableSlot<S>>,
    collapsing: boolean,
    timeZone: string,
    view: View,
    date?: string,
): Res<S> => {
    let nowPosition = 0;

    const initialTime = view === 'compact' ? 540 : 600;

    if (date && timeZone) {
        const [today, time] = convertUTCDateToZonedDate({
            date: new Date().toISOString(),
            timeZone,
            format: 'yyyy-MM-dd HH:mm',
        }).split(' ');

        nowPosition = today === date ? convertHHMMToMinutes(time) : 0;
    }

    const serializedSlots = slots.map((s) => {
        let startM = convertHHMMToMinutes(s.start);
        let endM = convertHHMMToMinutes(s.end);

        if (startM > endM) {
            endM += 24 * 60;
        } else if (s.date && date && date !== s.date) {
            startM += 24 * 60;
            endM += 24 * 60;
        }

        return {
            ...s,
            startM,
            endM,
        };
    });

    let range = [0, 0] as Res<S>['range'];

    const slotTimes = Object.keys(
        serializedSlots.reduce((acc, slot) => {
            const res = {
                ...acc,
                [slot.startM]: 0,
            };

            if (slot.endM > 59) {
                res[slot.endM] = 0;
            }

            return res;
        }, {}),
    ).sort((a, b) => +a - +b);

    const latestSlot = +slotTimes[slotTimes.length - 1];

    if (!collapsing) {
        return {
            range,
            nowPosition: getPx(nowPosition, view),
            serializedSlots: serializedSlots.map((s) => ({
                ...s,

                startM: getPx(s.startM, view),
                endM: getPx(s.endM, view),
            })),
            latestSlot,
        };
    }

    if (!slots.length) {
        range = [0, initialTime];

        return {
            range,
            nowPosition: getPx(nowPosition - initialTime, view),
            serializedSlots: [],
            latestSlot,
        };
    }

    if (+slotTimes[0] >= 300) {
        range = [0, +slotTimes[0] - (+slotTimes[0] % 60)];
        nowPosition = getPx(nowPosition - range[1], view);

        return {
            range,
            nowPosition: nowPosition > range[1] ? nowPosition : 0,

            serializedSlots: serializedSlots.map((s) => ({
                ...s,

                startM: getPx(s.startM - range[1], view),
                endM: getPx(s.endM - range[1], view),
            })),
            latestSlot,
        };
    }

    let maxInterval = 0;

    const minInterval = 120;

    range = slotTimes.reduce(
        (acc, slot, i) => {
            const timeDiff = +slotTimes[i + 1] - +slot;

            if (timeDiff > maxInterval && timeDiff > minInterval) {
                acc[0] = round(+slot);
                acc[1] = +slotTimes[i + 1];
                maxInterval = +slot;
            }

            return acc;
        },
        [0, 0] as Res<S>['range'],
    );

    const currrentPosition = () => {
        if (!nowPosition) return 0;

        if (nowPosition < range[0]) {
            return getPx(nowPosition, view);
        }

        if (nowPosition > range[0]) {
            return getPx(nowPosition - (range[1] - range[0]) + 60, view);
        }

        return 0;
    };

    const rangeEnd = range[1] - (range[1] % 60);

    return {
        range,
        nowPosition: currrentPosition(),
        serializedSlots: serializedSlots.map((s) => ({
            ...s,

            startM: getPx(
                s.startM < range[1] ? s.startM : s.startM - rangeEnd + range[0] + 60,
                view,
            ),
            endM: getPx(s.endM < range[1] ? s.endM : s.endM - rangeEnd + range[0] + 60, view),
        })),
        latestSlot,
    };
};
