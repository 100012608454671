/* eslint-disable complexity */
import React from 'react';
import {
    CalendarThinM,
    CashThinM,
    ChartThinM,
    DoorThinM,
    GearThinM,
    GiftThinM,
    PersonIosThinM,
    PersonsTwoM,
    SecurePaymentThinM,
    TicketThinM,
    WalletS,
} from '@alphakits/icons';
import { EmptyPage, TFunction } from '@alphakits/ui';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { CurrentUserRO } from '@escapenavigator/types/dist/user/current/current-user.ro';
import { CalendarPage } from 'src/pages/calendar';
import { ClientsPage } from 'src/pages/clients';
import { CashboxesPage } from 'src/pages/finance/cashboxes-settings';
import { FinanceitemsPage } from 'src/pages/finance/financeitems';
import { PartnersPage } from 'src/pages/finance/partners';
import { TransactionsPage } from 'src/pages/finance/transactions';
import { CertificateSalesPage } from 'src/pages/marketing/certificatesales';
import { PromocodesPage } from 'src/pages/marketing/promocodes';
import { SalesPage } from 'src/pages/marketing/sales';
import { UpsellingSettingsPage } from 'src/pages/marketing/upselling-settings';
import { OrdersPage } from 'src/pages/orders';
import { ApiKeysPage } from 'src/pages/settings/api-keys';
import { BookingSettingsPage } from 'src/pages/settings/booking-settings';
import { ImportPage } from 'src/pages/settings/import';
import { IntegrationsPage } from 'src/pages/settings/integrations';
import { PartnersProgramm } from 'src/pages/settings/partners-programm';
import { ProfilePage } from 'src/pages/settings/profile';
import { QuestroomsSettingsPage } from 'src/pages/settings/questrooms-settings';
import { SubscriptionPage } from 'src/pages/settings/subscription';
import { WaiverPage } from 'src/pages/settings/waivers';
import { WidgetsSettingsPage } from 'src/pages/settings/widgets-settings';
import { StatisticsPage } from 'src/pages/statistics';
import { PositionsPage } from 'src/pages/users/positions';
import { ReportsPage } from 'src/pages/users/reports';
import { RolesPage } from 'src/pages/users/roles';
import { UsersListPage } from 'src/pages/users/users-list';
import { UserSessionsPage } from 'src/pages/users/users-sessions';

import {
    POSITION_ROLES,
    ROUTE_API_KEYS,
    ROUTE_BOOKING_RULES,
    ROUTE_CALENDAR,
    ROUTE_CASHBOXES,
    ROUTE_CERTIFICATE_SALES,
    ROUTE_CLIENTS,
    ROUTE_CROSS_SALES,
    ROUTE_FINANCEITEMS,
    ROUTE_GAMES_HISTORY,
    ROUTE_IMPORT,
    ROUTE_INTEGRATIONS,
    ROUTE_ORGANIZATION,
    ROUTE_PARTNERS,
    ROUTE_PARTNERS_PROGRAMM,
    ROUTE_PROMOCODES,
    ROUTE_QUESTROOMS,
    ROUTE_ROLES,
    ROUTE_STATISTICS,
    ROUTE_SUBSCRIPTION,
    ROUTE_TRANSACTIONS,
    ROUTE_UPSELLINGS,
    ROUTE_USERS_LIST,
    ROUTE_USERS_REPORT,
    ROUTE_USERS_SESSIONS,
    ROUTE_WAIVER,
    ROUTE_WIDGETS,
} from './constants';
import { Fire } from './fire';
import { Route } from './types';

type Props = {
    t: TFunction;
    currentUser: CurrentUserRO;
    profile: ProfileRO;
};

export const notFoundRoute: Record<string, Route> = {
    '*': {
        type: 'path' as const,
        title: '',
        element: (
            <EmptyPage
                code="404"
                title="Page not found"
                onClick={ () => {
                    window.location.href = '/';
                } }
            />
        ),
        pure: true,
        hidePagetitle: true,
    },
};

export const getRoutes = ({ t, profile, currentUser: { role } }: Props): Record<string, Route> => {
    if (profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE) {
        return {
            ...notFoundRoute,

            // TODO добавить страницы для режима интеграции!
            // '/invoices': {
            //     title: t('Счета'),
            //     type: 'path',
            //     icon: <SecurePaymentThinM />,
            //     element: <SubscriptionPage />,
            // },

            // [ROUTE_BOOKING_RULES]: {
            //     type: 'path',
            //     icon: <TicketThinM />,
            //     title: t('menu./booking-settings'),
            //     element: <BookingSettingsPage />,
            // },

            '/': {
                type: 'path',
                icon: <DoorThinM />,
                title: t('Квесты'),
                element: <QuestroomsSettingsPage />,
            },

            [ROUTE_ORGANIZATION]: {
                type: 'path',
                title: t('menu./organizations'),
                icon: <GearThinM />,
                element: <ProfilePage />,
            },
            [ROUTE_SUBSCRIPTION]: {
                title: t('menu./subscription'),
                type: 'path',
                icon: <WalletS />,
                element: <SubscriptionPage />,
            },
            [ROUTE_PARTNERS_PROGRAMM]: {
                type: 'path',
                icon: <Fire />,
                title: t('menu./partners-program'),
                element: <PartnersProgramm />,
            },
        };
    }

    const routes: Record<string, Route> = {
        [ROUTE_CALENDAR]: {
            element: <CalendarPage />,
            title: t('menu./calendar'),
            hidePagetitle: true,
            type: 'path',
            icon: <CalendarThinM />,
        },
        [ROUTE_GAMES_HISTORY]: {
            title: t('menu./games-history'),
            element: <OrdersPage />,
            type: 'path',
            icon: <TicketThinM />,
        },
    };

    if (role.totalAccess || role.accessToClientsSection) {
        routes[ROUTE_CLIENTS] = {
            type: 'path',
            icon: <SecurePaymentThinM />,
            title: t('menu./clients'),
            element: <ClientsPage />,
        };
    }

    if (role.totalAccess || role.accessToMarketingSection) {
        routes.Marketing = {
            type: 'group',
            icon: <GiftThinM />,
            title: t('menu./marketing'),
            children: {
                [ROUTE_CERTIFICATE_SALES]: {
                    title: t('menu./certificate-sales'),
                    type: 'path',
                    onlyPro: true,
                    element: <CertificateSalesPage />,
                },
                [ROUTE_PROMOCODES]: {
                    title: t('menu./promocodes'),
                    type: 'path',
                    onlyPro: true,
                    element: <PromocodesPage />,
                },
                [ROUTE_CROSS_SALES]: {
                    title: t('menu./cross-sales'),
                    type: 'path',
                    onlyPro: true,
                    element: <SalesPage />,
                },
                [ROUTE_UPSELLINGS]: {
                    title: t('menu./upsellings'),
                    type: 'path',
                    onlyPro: true,
                    element: <UpsellingSettingsPage />,
                },
            },
        };
    }

    if (role.totalAccess || role.canEditUsersAccessAndTime) {
        routes.Users = {
            type: 'group',
            icon: <PersonIosThinM />,
            title: t('menu./users'),
            children: {
                [ROUTE_USERS_LIST]: {
                    type: 'path',
                    icon: <PersonsTwoM />,
                    title: t('menu./users-list'),
                    element: <UsersListPage />,
                },
            },
        };

        if (role.totalAccess || role.canEditUsersAccessAndTime) {
            routes.Users.children[ROUTE_USERS_SESSIONS] = {
                title: t('Working shifts'),
                type: 'path',
                onlyPro: true,
                element: <UserSessionsPage />,
            };
        }

        if (role.totalAccess) {
            routes.Users.children[ROUTE_ROLES] = {
                title: t('menu./roles'),
                type: 'path',
                element: <RolesPage />,
            };
        }

        if (role.totalAccess || role.canEditUsersAccessAndTime) {
            routes.Users.children[POSITION_ROLES] = {
                title: t('menu./positions'),
                type: 'path',
                element: <PositionsPage />,
            };
        }

        if (role.totalAccess) {
            routes.Users.children[ROUTE_USERS_REPORT] = {
                title: t('menu./users-reports'),
                type: 'path',
                onlyPro: true,
                element: <ReportsPage />,
            };
        }
    }

    if (role.totalAccess || role.accessToFinanceSection) {
        routes.Finance = {
            type: 'group',
            icon: <CashThinM />,
            title: t('menu./finance'),
            children: {
                [ROUTE_TRANSACTIONS]: {
                    title: t('menu./transactions'),
                    type: 'path',
                    onlyPro: true,
                    element: <TransactionsPage />,
                },
                [ROUTE_CASHBOXES]: {
                    title: t('menu./cashboxes'),
                    type: 'path',
                    element: <CashboxesPage />,
                },
                [ROUTE_FINANCEITEMS]: {
                    title: t('menu./financeitems'),
                    type: 'path',
                    onlyPro: true,
                    element: <FinanceitemsPage />,
                },
                [ROUTE_PARTNERS]: {
                    title: t('menu./partners'),
                    type: 'path',
                    onlyPro: true,
                    element: <PartnersPage />,
                },
            },
        };
    }

    if (role.totalAccess || role.accessToStatisticSection) {
        routes[ROUTE_STATISTICS] = {
            type: 'path',
            icon: <ChartThinM />,
            onlyPro: true,
            title: t('menu./statistics'),
            element: <StatisticsPage />,
        };
    }

    if (role.totalAccess || role.accessToSettingsSection) {
        routes.Settings = {
            type: 'group',
            icon: <GearThinM />,
            title: t('menu./settings'),
            children: {
                [ROUTE_QUESTROOMS]: {
                    title: t('Квесты'),
                    type: 'path',
                    element: <QuestroomsSettingsPage />,
                },
                [ROUTE_BOOKING_RULES]: {
                    title: t('menu./booking-settings'),
                    type: 'path',
                    element: <BookingSettingsPage />,
                },
                [ROUTE_WIDGETS]: {
                    title: t('menu./widgets'),
                    type: 'path',
                    element: <WidgetsSettingsPage />,
                },
                [ROUTE_INTEGRATIONS]: {
                    title: t('menu./integration'),
                    type: 'path',
                    onlyPro: true,
                    element: <IntegrationsPage />,
                },
                [ROUTE_ORGANIZATION]: {
                    title: t('Данные компании'),
                    type: 'path',
                    element: <ProfilePage />,
                },
                [ROUTE_SUBSCRIPTION]: {
                    title: t('menu./subscription'),
                    type: 'path',
                    element: <SubscriptionPage />,
                },
                [ROUTE_IMPORT]: {
                    title: t('Импорт данных'),
                    type: 'path',
                    element: <ImportPage />,
                },
                [ROUTE_WAIVER]: {
                    title: t('waiver'),
                    type: 'path',
                    element: <WaiverPage />,
                },
                [ROUTE_API_KEYS]: {
                    title: 'Api keys',
                    type: 'path',
                    element: <ApiKeysPage />,
                },

                [ROUTE_PARTNERS_PROGRAMM]: {
                    type: 'path',
                    title: t('menu./partners-program'),
                    element: <PartnersProgramm />,
                },
            },
        };
    }

    if (profile.partnerProgram !== PartnerProgramEnum.ACTIVE) {
        routes[ROUTE_PARTNERS_PROGRAMM] = {
            type: 'path',
            icon: <Fire />,
            title: t('menu./partners-program'),
            element: <PartnersProgramm />,
        };
    }

    return {
        ...notFoundRoute,
        ...routes,
    };
};
