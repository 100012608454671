import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListM } from '@alphakits/icons';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { QuestroomResourceRO } from '@escapenavigator/types/dist/questroom/questroom-resource.ro';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { ImagesGroup } from 'src/components/selects/base/images-group';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

export const getPrepaymentTitle = (
    prepaymentType: PrepaymentTypeEnum,
    prepayment: number,
    currency: ProfileCurrencyEnum,
    t,
) => {
    if (prepaymentType === PrepaymentTypeEnum.NO) return t('options.prepayment.no');
    if (prepaymentType === PrepaymentTypeEnum.FIXED) return <Amount type="decimal" value={ prepayment } currency={ currency } />;

    return <Amount type="decimal" value={ prepayment } currency="%" />;
};

export const ResourceColumns = (): TableColumns<QuestroomResourceRO> => {
    const { t } = useTranslation();

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    return [
        {
            header: t('tables.title'),
            accessor: 'title',
            row: {
                icon: () => <ListM />,
            },
        },
        {
            header: t('Количество'),
            accessor: 'amount',
        },
        {
            header: t('Квесты'),
            accessor: 'questroomsIds',
            row: {
                title: ({ row }) => (
                    <ImagesGroup
                        images={ questrooms.filter((q) => row.questroomsIds.includes(q.id)) }
                    />
                ),
            },
        },
    ];
};
