import React from 'react';
import {
    Checkbox, FlexColumns, Input, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateCustomFieldDto } from '@escapenavigator/types/dist/custom-field/create-custom-field.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const CustomFieldModal: React.FC<Props> = ({ close, t, cb }) => {
    const { customFieldsApi } = useApi();

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: customFieldsApi.create,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            title={ t('tables.title') }
            initialValues={ serializeRecord(CreateCustomFieldDto, {
                required: false,
            }) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('tables.title') }
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />

                    <Checkbox
                        label={ t('Обязательное поле') }
                        checked={ values.required }
                        onChange={ (e, { checked }) => setFieldValue('required', checked) }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
