import { convertMinutesToHHMM } from '@escapenavigator/utils/dist';

const isCollapsed = (collapsingTo: number, collapsingFrom: number, index: number) => {
    const c = index ? index > collapsingFrom : index >= collapsingFrom;

    return c && index < collapsingTo;
};

export const buildTimeIntervals = (
    collapsingRange: [number, number],
    collapsing: boolean,
    latestSlot: number,
) => {
    const intervals: Array<{ time: string; divider: boolean }> = [];

    const collapsingRangeEnd = collapsingRange[1] && collapsingRange[1] - (collapsingRange[1] % 60);

    for (let index = 0; index <= Math.max(1440, latestSlot); index += 60) {
        if (
            !collapsing ||
            !(collapsingRangeEnd && isCollapsed(collapsingRangeEnd, collapsingRange[0], index))
        ) {
            intervals.push({
                time: convertMinutesToHHMM(index),
                divider: isCollapsed(collapsingRangeEnd, collapsingRange[0], index + 60),
            });
        }
    }

    return intervals;
};
