import { SlotTemplateElement } from '@escapenavigator/types/dist/slot-template/slot-template-element';
import { SlotAutocompliteFormDto } from '@escapenavigator/types/dist/slot/slot-autocomplite-form.dto';
import { convertHHMMToMinutes, convertMinutesToHHMM } from '@escapenavigator/utils/dist';

export const getOptionForTimeSelect = (step = 5, min?: string) => {
    const options = [];

    const from = min ? convertHHMMToMinutes(min) : 0;

    for (let index = from; index < 1440; index += step) {
        options.push(convertMinutesToHHMM(index));
    }

    return options.map((o) => ({
        key: o,
        content: o,
    }));
};

const TFH = 24 * 60;

export const buildTemplate = (
    values: Omit<SlotAutocompliteFormDto, 'ruleData'>,
    duration = 60,
): SlotTemplateElement[] => {
    const template = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    };
    const { days } = values;

    const start = convertHHMMToMinutes(values.start);
    let end = convertHHMMToMinutes(values.end);

    if (start > end) end += TFH;

    days.forEach((day) => {
        for (let index = start; index <= end; index += +duration + +values.breakInterval) {
            let actualDay = index >= TFH ? day + 1 : day;

            if (actualDay === 7) actualDay = 0;

            const actualIndex = index >= TFH ? index - TFH : index;

            template[actualDay].push({
                date: actualDay,
                start: convertMinutesToHHMM(actualIndex),
                end: convertMinutesToHHMM(actualIndex + duration),
                tariffId: values.tariffId,
                ruleId: values.ruleId,
            });
        }
    });

    return [...Object.values(template).flat()];
};
