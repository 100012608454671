import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Flex,
    FlexColumns,
    ModalContext,
    showError,
    showSuccess,
    Table,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CashboxTypeEnum } from '@escapenavigator/types';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { isRu } from '@escapenavigator/utils/dist/is-ru';
import { Section } from 'src/components/layout/section';
import { Picker } from 'src/components/picker';
import { ImagesGroup } from 'src/components/selects/base/images-group';
import { CashboxOnlineModal } from 'src/modals/cashbox-online';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCashboxes, selectAllCertificates, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { CashboxColumns } from './cashbox-columns';

export const OnlinePayments: React.FC = () => {
    const { i18n, t } = useTranslation();
    const {
        current: {
            profileId,
            user: { email },
        },
    } = useCurrentUser();

    const { openModal } = useContext(ModalContext);

    const {
        cashboxes: { getVerificationLink, createOnline },
    } = useApi();

    const cashboxes = useAppSelector(selectAllCashboxes);
    const certificates = useAppSelector(selectAllCertificates);
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const onlineCashboxes = cashboxes
        .filter(
            (cashbox) =>
                cashbox.type === CashboxTypeEnum.STRIPE ||
                cashbox.type === CashboxTypeEnum.PAYPAL ||
                cashbox.type === CashboxTypeEnum.BS,
        )
        .sort((a) => (a.type === CashboxTypeEnum.STRIPE ? 1 : -1));

    const stripeCashboxes = cashboxes.filter((cashbox) => cashbox.type === CashboxTypeEnum.STRIPE);

    const emptyQuestrooms = useMemo(() => {
        const paypal = questrooms.filter((q) => !q.paypalCashbox);
        const stripe = questrooms.filter((q) => !q.onlinePaymentsCashbox);
        const online = questrooms.filter((q) => !q.onlinePaymentsCashbox && !q.paypalCashbox);

        return { paypal, stripe, online };
    }, [questrooms]);

    const emptyCertificates = useMemo(() => {
        const paypal = certificates.filter((q) => !q.paypalCashbox);
        const bs = certificates.filter((q) => !q.bsCashbox);
        const stripe = certificates.filter((q) => !q.onlinePaymentsCashbox);
        const online = certificates.filter(
            (q) => !q.onlinePaymentsCashbox && !q.paypalCashbox && !q.bsCashbox,
        );

        return {
            paypal,
            stripe,
            online,
            bs,
        };
    }, [certificates]);

    const { getVerificationLinkFetch, getVerificationLinkLoading } = useApiMethod({
        api: getVerificationLink,
        successCallback: ({ data }) => {
            window.location.href = data;
        },
    });

    const cashboxColumns = CashboxColumns();
    const hasVerified = onlineCashboxes?.some((c) => c.identificator);

    const { createOnlineFetch, createOnlineLoading } = useApiMethod({
        api: createOnline,
        successCallback: ({ data }) => getVerificationLinkFetch(data.id),
        errorCallback: ({ message }) => showError(message),
    });

    const pay = () => {
        const widget = new (window as any).cp.CloudPayments();

        return widget.pay(
            'charge',
            {
                publicId: process.env.REACT_APP_CLOUD_PAYMENT_PUB,
                description: 'Привязка карты для выплат от Escape Navigator',
                amount: 1,
                accountId: email,
                currency: 'RUB',
                data: {
                    profileId,
                    type: 'token',
                },
            },
            {
                onSuccess() {
                    showSuccess(
                        'Карта привязана. Средства будут зачислены на ваш счет в течение нескольких минут.',
                    );
                },
                onFail(reason) {
                    showError(reason, 'Не удалось произвести оплату');
                },
                // onComplete(paymentResult, options) {},
            },
        );
    };

    const actions = isRu()
        ? { 'Добавить карту для выплат (физ лицо)': pay }
        : {
            Stripe: () =>
                createOnlineFetch({
                    data: {
                        type: CashboxTypeEnum.STRIPE,
                        title: `Stripe wallet ${
                            stripeCashboxes.length ? stripeCashboxes.length + 1 : ''
                        }`,
                        increaseType: PrepaymentTypeEnum.NO,
                        increase: 0,
                        questroomsIds: emptyQuestrooms.stripe.map((q) => q.id),
                        certificatesIds: emptyCertificates.stripe.map((q) => q.id),
                    },
                }),
            Paypal: () => openModal(CashboxOnlineModal)(),
        };

    const errorLevel = useMemo(() => {
        if (!!emptyQuestrooms.online?.length || !!emptyCertificates.online?.length) return 'online';
        if (!!emptyQuestrooms.paypal?.length || !!emptyCertificates.paypal?.length) return 'paypal';
        if (!!emptyQuestrooms.stripe?.length || !!emptyCertificates.stripe?.length) return 'stripe';

        return null;
    }, [emptyQuestrooms, emptyCertificates]);

    return (
        <Section
            id="onlinePayments"
            title={ t('Онлайн оплата') }
            subtitle={ !hasVerified && t('addStripe.description') }
            initial={ !hasVerified }
            buttonAddon={ (
                <div>
                    <Picker
                        t={ t }
                        size={ hasVerified ? 'xs' : 's' }
                        loading={ getVerificationLinkLoading || createOnlineLoading }
                        view={ hasVerified ? 'outlined' : 'primary' }
                        position="bottom-end"
                        content={ t('Добавить') }
                        actions={ actions }
                    />
                </div>
            ) }
        >
            { isRu() && (
                <ToastPlate view="attention" color="primary" slot="sxs">
                    { !hasVerified &&
                        'Чтобы закончить процесс онбординга, добавьте карту для выплат за онлайн оплату.' }
                    Оплнайн оплата будет доступна 01 февраля 2025 года.
                </ToastPlate>
            ) }

            { hasVerified && (
                <React.Fragment>
                    { !!errorLevel && !isRu() && (
                        <ToastPlate
                            view="attention"
                            color="primary"
                            title={ t('Не назначен метод оплаты') }
                            slot="sxs"
                        >
                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <Typography.Text view="title" color="primary">
                                    { t('noOnlinePaymentHint', { value: errorLevel.toUpperCase() }) }
                                </Typography.Text>

                                <Flex justify="start">
                                    { !!emptyQuestrooms[errorLevel]?.length && (
                                        <ImagesGroup images={ emptyQuestrooms[errorLevel] } />
                                    ) }
                                    { !!emptyCertificates[errorLevel]?.length && (
                                        <ImagesGroup images={ emptyCertificates[errorLevel] } />
                                    ) }
                                </Flex>
                            </FlexColumns>
                        </ToastPlate>
                    ) }

                    <Table.TableComponent
                        columns={ cashboxColumns }
                        hideSearch={ true }
                        height="default"
                        onRowClick={ (row) => openModal(CashboxOnlineModal)({ recordId: row.id }) }
                        language={ getLanguage(i18n) }
                        records={ onlineCashboxes }
                    />
                </React.Fragment>
            ) }
        </Section>
    );
};
