import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, FlexColumns, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { InvoiceStatusEnum } from '@escapenavigator/types/dist/profile/enum/invoice-status.enum';
import { Section } from 'src/components/layout/section';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';
import { SubscriptionSection } from './subscription-section';

export const SubscriptionPageCOM: React.FC = () => {
    const { t, i18n } = useTranslation();

    const {
        current: {
            extra: { currency },
        },
        setProfile,
    } = useCurrentUser();

    const { profiles } = useApi();

    const { getSubscriptionInfoFetch, getSubscriptionInfoData, getSubscriptionInfoLoading } =
        useApiMethod({
            api: profiles.getSubscriptionInfo,
            successCallback: ({ data }) => setProfile(data.profile),
        });

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { createPaymentMethodSessionFetch, createPaymentMethodSessionLoading } = useApiMethod({
        api: profiles.createPaymentMethodSession,
        successCallback: ({ data }) => {
            window.location.href = data;
        },
    });

    const { getProfileInvoicesFetch, getProfileInvoicesData, getProfileInvoicesLoading } =
        useApiMethod({
            api: profiles.getProfileInvoices,
        });

    useEffect(() => {
        getProfileInvoicesFetch(undefined);
        getSubscriptionInfoFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <SubscriptionSection loading={ getSubscriptionInfoLoading } />

            <Section
                buttonLabel={
                    getSubscriptionInfoData?.paymentSettings?.paymentMethod
                        ? t('Изменить')
                        : t('Add payment method')
                }
                buttonLoading={ createPaymentMethodSessionLoading }
                loading={ getSubscriptionInfoLoading }
                onClick={ () => createPaymentMethodSessionFetch(undefined) }
                title={ t('Payment info') }
            >
                <FlexColumns columns={ 3 }>
                    <Cell.Base
                        title={ t('Payment method') }
                        subtitle={
                            getSubscriptionInfoData?.paymentSettings?.paymentMethod
                                ? `${getSubscriptionInfoData?.paymentSettings?.paymentMethod?.brand} ${getSubscriptionInfoData?.paymentSettings?.paymentMethod?.last4} ${getSubscriptionInfoData?.paymentSettings?.paymentMethod?.exp}`
                                : t('Нет данных')
                        }
                    />
                    <Cell.Base
                        title={ t('Payee') }
                        subtitle={
                            getSubscriptionInfoData?.paymentSettings?.billingEmail || 'xxxxxx'
                        }
                    />
                </FlexColumns>
            </Section>

            <Section title={ t('Счета') }>
                <Table.TableComponent
                    columns={ Columns(t, currency, i18n) }
                    hideSearch={ true }
                    height="default"
                    loading={ getProfileInvoicesLoading }
                    language={ getLanguage(i18n) }
                    records={
                        getProfileInvoicesData?.invoices.filter(
                            (b) => b.status !== InvoiceStatusEnum.DRAFT,
                        ) || []
                    }
                />
            </Section>
        </FlexColumns>
    );
};
