import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput, ModalContext, Select, showError, Sidepanel,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrossSaleStatusEnum } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale-status.enum';
import { CrossSaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { CrossSaleDiscountTypeEnum } from '@escapenavigator/types/dist/profile/cross-sale/crossale-discount-type.enum';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { getHandlers } from '../../../../components/quill/get-handlers';
import { EmailExampleModal } from '../common/email-example';
import { CommonCrossSaleForm } from '../common/form';
import { useLocales } from '../common/hooks/use-locale';
import { deepEqual } from '../common/utils/deep-equal';
import { getPromocodeError } from '../common/utils/get-promocode-error';

type Props = {
    close: () => void;
    cb: () => void;
    data: CrossSaleDto;
    defaultValues: CrossSaleDto;
};

export const CrossSalesModal: React.FC<Props> = ({
    close, data, cb, defaultValues,
}) => {
    const { crossSalesApi } = useApi();
    const { openModal } = useContext(ModalContext);

    const { t } = useTranslation();
    const { profile } = useCurrentUser();

    const { updateCrossSaleFetch, updateCrossSaleLoading } = useApiMethod({
        api: crossSalesApi.updateCrossSale,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => {
            close();
            cb();
        },
    });

    const locales = useLocales({ locales: data.locales });

    return (
        <Sidepanel
            subtitle={ t('crossSaleDescription') }
            title={ t('crossSale') }
            loading={ updateCrossSaleLoading }
        >
            <BaseForm
                initialValues={ serializeRecord(CrossSaleDto, {
                    ...data,
                    locales,
                    status: CrossSaleStatusEnum.ENABLED,
                }) }
                loading={ updateCrossSaleLoading }
                validate={ validateByDto(t) }
                save={ (values) => updateCrossSaleFetch(values) }
                t={ t }
            >
                { ({
                    values, errors, setFieldValue, submitForm, touched, setValues,
                }) => (
                    <CommonCrossSaleForm
                        errors={ errors }
                        handlers={ getHandlers('crossSale', t) }
                        close={ close }
                        submit={ submitForm }
                        setFieldValue={ setFieldValue }
                        onDefaultsClick={ () => setValues(defaultValues) }
                        showDefaults={ !deepEqual(values, defaultValues) }
                        locales={ values.locales }
                        customError={ getPromocodeError({ values, t }) }
                        onExampleClick={ () => {
                            openModal(
                                EmailExampleModal,
                                'm',
                            )({
                                record: values,
                                subtitile: t('crossSale'),
                            });
                        } }
                    >
                        <Select
                            label={ t('Скидка на следующую бронь') }
                            options={ enumToOptions(CrossSaleDiscountTypeEnum, t, 'crosssaleType') }
                            selected={ values.discountType }
                            onChange={ ({ selected }) =>
                                setFieldValue('discountType', selected?.key) }
                            block={ true }
                        />

                        { values.discountType !== CrossSaleDiscountTypeEnum.NONE && (
                            <React.Fragment>
                                <AmountInput
                                    dataTestId="minDaysForFreeCanceling"
                                    label={ t('component.amount') }
                                    value={ values.discount }
                                    type="decimal"
                                    suffix={
                                        (values.discountType === CrossSaleDiscountTypeEnum.PERCENT
                                            ? '%'
                                            : profile.currency) as any
                                    }
                                    error={ touched.discount && errors.discount }
                                    onChange={ (_, { value }) => setFieldValue('discount', value) }
                                    block={ true }
                                />

                                <AmountInput
                                    dataTestId="discountValidityDays"
                                    label={ t('Срок действия промокода') }
                                    suffix={ t('common:дней') }
                                    value={ values.discountValidityDays }
                                    onChange={ (e, { value }) =>
                                        setFieldValue('discountValidityDays', value) }
                                    error={
                                        touched.discountValidityDays && errors.discountValidityDays
                                    }
                                    block={ true }
                                />
                            </React.Fragment>
                        ) }
                        <AmountInput
                            dataTestId="amount"
                            label={ t('Отправить с момента игры') }
                            suffix={ t('common:часов') }
                            value={ values.sendAfter }
                            onChange={ (e, { value }) => setFieldValue('sendAfter', value) }
                            error={ touched.sendAfter && errors.sendAfter }
                            block={ true }
                        />
                    </CommonCrossSaleForm>
                ) }
            </BaseForm>
        </Sidepanel>
    );
};
