import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumns, ToastPlate } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { GeneralStatiscticsRO } from '@escapenavigator/types/dist/statistics/general';
import { Section } from 'src/components/layout/section';
import { useApi } from 'src/providers/api/context';
import { selectAllCertificates, selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { AmountBox } from '../components/amount-box';
import { BarChartComponent } from '../components/bar';
import { DoublePieComponent } from '../components/double-pie';
import { useLocationsSelect } from '../components/use-locations-select';
import { View, YearsPicker, YearsPickerProps } from '../components/years-picker';

const sources: Record<SourceEnum, string> = {
    [SourceEnum.AGREGATOR]: 'Escape Navigator',
    [SourceEnum.USER]: 'By phone',
    [SourceEnum.SUPPORT]: 'Escape Navigator',
    [SourceEnum.WIDGET]: 'Online',
    [SourceEnum.nowEscape]: 'NowEscape.com',
    [SourceEnum.exportded]: 'Exported',
};

export const GeneralStatistics: React.FC = () => {
    const { t } = useTranslation();
    const { statiscticsApi } = useApi();

    const {
        generalStatiscticsData,
        generalStatiscticsError,
        generalStatiscticsFetch,
        generalStatiscticsLoading,
    } = useApiMethod({
        api: statiscticsApi.generalStatisctics,
    });

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const locations = useAppSelector(selectAllLocations);
    const certificates = useAppSelector(selectAllCertificates);

    const { selectedQuestroomsIds, Locations } = useLocationsSelect();
    const [params, setParams] = useState<{
        view: View;
        date: string;
    }>();

    const handleChangeParams: YearsPickerProps['onChange'] = (params) => setParams(params);

    useEffect(() => {
        if (params) {
            generalStatiscticsFetch({
                questroomsIds: selectedQuestroomsIds,
                type: params.view,
                date: params.date,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQuestroomsIds, params]);

    const {
        newBookingsByQuestroom,
        newBookingsByQuestroomAndDate,
        newBookingsStructure,
        newCertificatesByDate,
    }: Partial<GeneralStatiscticsRO> = useMemo(
        () => ({
            newCertificatesByDate: generalStatiscticsData?.newCertificatesByDate || [],
            newBookingsStructure: generalStatiscticsData?.newBookingsStructure || [],
            newBookingsByQuestroomAndDate:
                generalStatiscticsData?.newBookingsByQuestroomAndDate || [],
            newBookingsByQuestroom: generalStatiscticsData?.newBookingsByQuestroom || [],
        }),
        [generalStatiscticsData],
    );

    const newBookingsByQuestroomAndDateMap = useMemo(
        () =>
            newBookingsByQuestroomAndDate.map(({ name, ...d }) => ({
                name,
                ...Object.fromEntries(
                    Object.entries(d).map(([q, val]) => [
                        questrooms.find((i) => i.id === +q)?.title || 'Deleted',
                        val,
                    ]),
                ),
            })),
        [newBookingsByQuestroomAndDate, questrooms],
    );

    const newBookingsStructureMap = useMemo(
        () =>
            newBookingsStructure.map(({ name, ...d }) => ({
                name,
                ...Object.fromEntries(
                    Object.entries(d).map(([q, val]) => [sources[q] || 'Deleted', val]),
                ),
            })),
        [newBookingsStructure],
    );

    const newCertificatesByDateMap = useMemo(
        () =>
            newCertificatesByDate.map(({ name, ...d }) => ({
                name,
                ...Object.fromEntries(
                    Object.entries(d).map(([q, val]) => [
                        certificates.find((i) => i.id === +q)?.title || 'Deleted',
                        val,
                    ]),
                ),
            })),
        [newCertificatesByDate, certificates],
    );

    const [escapeBookings, locationBookings] = useMemo(() => {
        const arr = Object.values(
            newBookingsByQuestroom.reduce((acc, val) => {
                const questroom = questrooms.find((q) => q.id === val.name);

                if (!questroom) return acc;

                const l = locations.find((l) => l.id === questroom.locationId);

                acc[l.title] = acc[l.title] || {
                    name: l.title,
                    value: 0,
                    questrooms: [],
                };

                acc[l.title].value += val.value;

                acc[l.title].questrooms = [
                    ...acc[l.title].questrooms,
                    {
                        name: questroom.title,
                        value: val.value,
                    },
                ];

                return acc;
            }, {}),
        ) as Array<{
            name: number;
            value: number;
            questrooms: Array<{ name: number; value: number }>;
        }>;

        const questroomsData = arr.reduce((acc, val) => [...acc, ...val.questrooms], []);
        const locationsData =
            arr.length > 1 ? arr.map(({ name, value }) => ({ name, value })) : null;

        return [questroomsData, locationsData];
    }, [newBookingsByQuestroom, locations, questrooms]);

    return (
        <FlexColumns gr={ 24 } columns={ 1 }>
            <YearsPicker onChange={ handleChangeParams } />
            { Locations }

            { generalStatiscticsError?.message ? (
                <ToastPlate view="negative">{ generalStatiscticsError.message }</ToastPlate>
            ) : (
                <React.Fragment>
                    <br />

                    <FlexColumns columns={ 3 }>
                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.newBookings.revenue }
                            title={ `New bookings: ${
                                generalStatiscticsData?.newBookings.count || 0
                            }` }
                        />

                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.newCertificates.revenue }
                            title={ `New certificates: ${
                                generalStatiscticsData?.newCertificates.count || 0
                            }` }
                        />

                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.promocodes.revenue }
                            title={ `Promocodes applied: ${
                                generalStatiscticsData?.promocodes.count || 0
                            }` }
                        />

                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.usedCertificates.revenue }
                            title={ `Certificates applied: ${
                                generalStatiscticsData?.usedCertificates.count || 0
                            }` }
                        />

                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.handDiscounts.revenue }
                            title={ `Hand discounts: ${
                                generalStatiscticsData?.handDiscounts.count || 0
                            }` }
                        />
                        <AmountBox
                            loading={ generalStatiscticsLoading }
                            amount={ generalStatiscticsData?.upsellings.revenue }
                            title={ `Upsellings: ${generalStatiscticsData?.upsellings.count || 0}` }
                        />
                    </FlexColumns>

                    <Section loading={ generalStatiscticsLoading } title="Bookings">
                        <BarChartComponent data={ newBookingsByQuestroomAndDateMap } />
                    </Section>

                    <Section loading={ generalStatiscticsLoading } title="Booking sources">
                        <BarChartComponent data={ newBookingsStructureMap } />
                    </Section>

                    <Section loading={ generalStatiscticsLoading } title={ t('Квесты') }>
                        <DoublePieComponent data1={ locationBookings } data2={ escapeBookings } />
                    </Section>

                    <Section loading={ generalStatiscticsLoading } title="Certificate sales">
                        <BarChartComponent data={ newCertificatesByDateMap } />
                    </Section>

                    <Section loading={ generalStatiscticsLoading } title="Upsellings">
                        <DoublePieComponent data2={ generalStatiscticsData?.bookingsByUpselling } />
                    </Section>

                    { /*
                    <Section loading={ generalStatiscticsLoading } title="Cashboxes">
                        <DoublePieComponent currency={ profile.currency } data2={ cashboxes } />
                    </Section>

                    <Section loading={ generalStatiscticsLoading } title="Structure">
                        <BarChartComponent currency={ profile.currency } data={ structure } />
                    </Section>
                        */ }
                </React.Fragment>
            ) }
        </FlexColumns>
    );
};
