import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput,
    CalendarInput,
    Checkbox,
    FlexColumns,
    formatDate,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateSlotFormDto } from '@escapenavigator/types/dist/slot/create-slot-form.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { TariffSelect } from 'src/components/tariff-select';
import { TimeInput } from 'src/components/time-input';
import { useApi } from 'src/providers/api/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { useRulesApi } from 'src/store/hooks/use-rules';

import { getOptionForTimeSelect } from '../autocomplite/utils';
import { BookingRule } from '../booking-rules';

type Props = {
    close: () => void;
    callback: () => void;
    date: string;
};

export const CreateSingleSlotModal: React.FC<Props> = ({ close, callback, date }) => {
    const { t, i18n } = useTranslation();

    const questrooms = useAppSelector(selectAllQuestrooms({}));

    const { slots } = useApi();

    const {
        rules, createRule, loading, updating,
    } = useRulesApi();

    const { createLoading, createError, createFetch } = useApiMethod({
        api: slots.create,
        successCallback: () => {
            close();
            if (callback) callback();
        },
    });

    const handleSubmit = async (values: CreateSlotFormDto) => {
        let { ruleId } = values;

        if (!ruleId) {
            const rule = await createRule({
                title: values.title,
                minHoursForBooking: values.minHoursForBooking,
                minHoursForFreeCanceling: values.minHoursForFreeCanceling,
                blockingHours: values.blockingHours,
                openSlotWhenUserInCalendar: values.openSlotWhenUserInCalendar,
                prepayForTourists: values.prepayForTourists,
                prepayment: values.prepayment,
                prepaymentType: values.prepaymentType,
                cancelationRule: values.cancelationRule,
                cancelationAmount: values.cancelationAmount,
                saved: values.saved,
            });

            if (!rule) return;

            ruleId = rule.id;
        }

        createFetch({
            data: {
                date: values.date,
                start: values.start,
                questroomId: values.questroomId,
                tariffId: values.tariffId,
                discount: values.discount,
                onlyPhone: values.onlyPhone,
                ruleId,
            },
        });
    };

    return (
        <RestForm
            title={ `${t('Создание слота')}` }
            save={ handleSubmit }
            close={ close }
            t={ t }
            validate={ validateByDto(t) }
            initialValues={ serializeRecord(CreateSlotFormDto, {
                date: date || formatDate(new Date(), { format: 'yyyy-MM-dd', lang: i18n.language }),
                tariffId: null,
                ruleId: rules[0]?.id,
            }) }
            softError={ createError?.message }
            loading={ createLoading || loading || updating }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 32 }>
                    <FlexColumns columns={ 1 } gr={ 20 }>
                        <Typography.Title
                            tag="div"
                            defaultMargins={ true }
                            view="xxsmall"
                            weight="bold"
                        >
                            { t('Детали') }
                        </Typography.Title>

                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <QuestroomsSelect
                                multiple={ false }
                                label={ t('Выберите квест') }
                                questrooms={ questrooms }
                                selected={ values.questroomId }
                                error={ touched.questroomId && errors.questroomId }
                                onChange={ ({ selected }) => {
                                    setFieldValue('questroomId', selected.key);
                                } }
                            />

                            <CalendarInput
                                value={ values.date }
                                lang={ i18n.language }
                                label={ t('date') }
                                onChange={ (e, { value }) => setFieldValue('date', value) }
                                block={ true }
                                error={ touched.date && errors.date }
                            />

                            <TimeInput
                                label={ t('Время начала игры') }
                                block={ true }
                                options={ getOptionForTimeSelect() }
                                selected={ values.start }
                                error={ touched.start && errors.start }
                                onChange={ ({ key }) => setFieldValue('start', key) }
                            />

                            <TariffSelect
                                value={ values.tariffId }
                                onChange={ (id) => setFieldValue('tariffId', id) }
                                error={ touched.tariffId && errors.tariffId }
                            />

                            <AmountInput
                                label={ t('Скидка') }
                                value={ values.discount }
                                suffix={ '%' as any }
                                onChange={ (e, { value }) => setFieldValue('discount', value) }
                                block={ true }
                                type="decimal"
                            />

                            <Checkbox
                                label={ t('Available by phone only') }
                                checked={ values.onlyPhone }
                                onChange={ (e, { checked }) => setFieldValue('onlyPhone', checked) }
                                block={ true }
                            />
                        </FlexColumns>
                    </FlexColumns>

                    <BookingRule
                        values={ values }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        touched={ touched }
                        rules={ rules || [] }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
