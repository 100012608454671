import React from 'react';
import { TabPanel, Tabs } from 'src/modals/components/tabs';

import { CludPaymentsForm } from './cloud-payments-form';
import { BalanceDetalization } from './detalization';
import { BalancePayments } from './payments';

export const SubscriptionPageRu: React.FC = () => (
    <Tabs defaultActive="Пополнение">
        <TabPanel name="Пополнение">
            <CludPaymentsForm />
        </TabPanel>

        <TabPanel name="Детализация">
            <BalanceDetalization />
        </TabPanel>

        <TabPanel name="Счета">
            <BalancePayments />
        </TabPanel>
    </Tabs>
);
