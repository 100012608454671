import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Button, ModalContext, showError, showSuccess, Sidepanel, Table,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { SmtpRO } from '@escapenavigator/types/dist/profile/smtp/smtp.ro';
import { useApi } from 'src/providers/api/context';
import { selectAllLocations } from 'src/store';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';
import { SmtpModal } from './create';

export const SmtpManagerModal: React.FC = () => {
    const { profiles } = useApi();
    const { openModal } = useContext(ModalContext);
    const { t, i18n } = useTranslation();
    const locations = useSelector(selectAllLocations);

    const { getSmtpsData, getSmtpsLoading, getSmtpsFetch } = useApiMethod({
        api: profiles.getSmtps,
    });

    const { removeSmtpLoading, removeSmtpFetch } = useApiMethod({
        api: profiles.removeSmtp,
        successCallback: () => getSmtpsFetch(undefined),
    });

    const { testSmtpLoading, testSmtpFetch } = useApiMethod({
        api: profiles.testSmtp,
        successCallback: () => showSuccess('SMTP data looks good!'),
        errorCallback: () => showError('SMTP data incorrect!'),
    });

    const openSmtp = (record?: SmtpRO) =>
        openModal(SmtpModal)({
            cb: () => getSmtpsFetch(undefined),
            record,
        });

    useEffect(() => {
        getSmtpsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = Columns({
        t,
        locations,
        removeSmtpFetch,
        testSmtpFetch,
    });

    return (
        <Sidepanel
            title="SMTP"
            headerRightAddons={ (
                <Button view="outlined" size="xs" onClick={ () => openSmtp() }>
                    { t('Добавить') }
                </Button>
            ) }
        >
            <Table.TableComponent
                columns={ columns }
                onRowClick={ openSmtp }
                hideSearch={ true }
                height="default"
                language={ getLanguage(i18n) }
                records={ getSmtpsData }
                loading={ getSmtpsLoading || removeSmtpLoading || testSmtpLoading }
            />
        </Sidepanel>
    );
};
