/* eslint-disable no-restricted-globals */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListM, TrashS } from '@alphakits/icons';
import { Button, showError, showSuccess } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CustomFieldRO } from '@escapenavigator/types/dist/custom-field/custom-field.ro';
import { useApi } from 'src/providers/api/context';

const Remove = ({ id, refetch }: { id: number; refetch: () => void }) => {
    const { customFieldsApi } = useApi();
    const { removeFetch, removeLoading } = useApiMethod({
        api: customFieldsApi.remove,
        successCallback: () => {
            showSuccess('Removed');
            refetch();
        },
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Button
            size="xs"
            loading={ removeLoading }
            leftAddons={ <TrashS /> }
            onClick={ () => confirm('Are you sure?') && removeFetch(id) }
        />
    );
};

export const Columns = (refetch): TableColumns<CustomFieldRO> => {
    const { t } = useTranslation();

    return [
        {
            header: t('tables.title'),
            accessor: 'title',
            row: {
                icon: () => <ListM />,
            },
        },
        {
            header: '',
            accessor: 'required',
            row: {
                title: ({ row }) => row.required && t('Обязательное поле'),
            },
        },
        {
            header: '',
            accessor: 'id',
            width: '100px',
            row: {
                title: ({ row }) => <Remove refetch={ refetch } id={ row.id } />,
            },
        },
    ];
};
