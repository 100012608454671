/* eslint-disable @typescript-eslint/no-explicit-any */

// import { SerializedTableSlot } from '../..';

// const isIntercept = <T>(current: SerializedTableSlot<T>, arr: Array<SerializedTableSlot<T>>) =>
//     arr.some(
//         (slot) =>
//             (current.start >= slot.start && current.start < slot.end) ||
//             (current.end < slot.end && current.end > slot.start) ||
//             (slot.end < current.end && slot.end > current.start) ||
//             (slot.end < current.end && slot.end > current.start),
//     );

// export const getSlotOffset = <T>(
//     slots: Array<SerializedTableSlot<T>>,
//     currentIndex = 0,
// ): Array<SerializedTableSlot<T>> => {
//     const newSlots = slots.map((slot, _, arr) => {
//         const definedSlots = arr.filter((s) => s.indexOffset === currentIndex);

//         if (!isIntercept(slot, definedSlots) && typeof slot.indexOffset === 'undefined') {
//             // eslint-disable-next-line no-param-reassign
//             slot.indexOffset = currentIndex;
//         }

//         return slot;
//     });

//     return newSlots.some((slot) => typeof slot.indexOffset === 'undefined')
//         ? getSlotOffset(newSlots, currentIndex + 1)
//         : newSlots;
// };

export function assignIndexOffsets(slots) {
    const offsets = new Map();

    // Сортируем слоты по началу
    slots.sort((a, b) => a.start - b.start);

    for (let i = 0; i < slots.length; i++) {
        const slotA = slots[i];
        let indexOffset = 0;

        // Проверяем пересечения с предыдущими слотами
        for (let j = 0; j < i; j++) {
            const slotB = slots[j];

            // Если слоты пересекаются
            if (slotA.endM > slotB.startM && slotA.startM < slotB.endM) {
                // Увеличиваем indexOffset
                indexOffset = Math.max(indexOffset, (offsets.get(slotB) || 0) + 1);
            }
        }

        // Устанавливаем indexOffset для текущего слота
        offsets.set(slotA, indexOffset);
    }

    // Преобразуем в массив с indexOffset
    return slots.map((slot) => ({ ...slot, indexOffset: offsets.get(slot) || 0 }));
}
