import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { Section } from 'src/components/layout/section';
import { CustomFieldModal } from 'src/modals/custom-filed';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';

export const CustomFields: React.FC = () => {
    const { i18n, t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const { customFieldsApi } = useApi();

    const { queryData, queryFetch, queryLoading } = useApiMethod({
        api: customFieldsApi.query,
    });

    useEffect(() => {
        queryFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = Columns(queryFetch);

    const openRuleModal = openModal(CustomFieldModal);

    return (
        <Section
            title={ t('customFields') }
            id="customFieldsSettings"
            subtitle={ !queryData?.length && '' }
            onClick={ () => openRuleModal({ cb: () => queryFetch(undefined) }) }
            initial={ !queryData?.length }
            loading={ queryLoading }
        >
            { !!queryData?.length && (
                <Table.TableComponent
                    columns={ columns }
                    hideSearch={ true }
                    height="default"
                    language={ getLanguage(i18n) }
                    records={ queryData || [] }
                    loading={ queryLoading }
                />
            ) }
        </Section>
    );
};
