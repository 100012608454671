import React from 'react';
import {
    convertToOptions,
    Divider,
    FlexColumns,
    Input,
    Select,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CashboxTypeEnum, CreateCashboxDto } from '@escapenavigator/types/dist';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { LocationsPicker } from 'src/components/locations-picker';
import { useApi } from 'src/providers/api/context';
import { addCashbox, removeCashbox, selectAllLocations } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    recordId?: number;
    close: () => void;
    t: TFunction;
};

export const CashboxModal: React.FC<Props> = ({ recordId, close, t }) => {
    const dispatch = useAppDispatch();

    const { cashboxes } = useApi();

    const { record, loading, error } = useGetOne(recordId, cashboxes.getOne);
    const locations = useAppSelector(selectAllLocations);

    const cashboxTypeOptions = enumToOptions(CashboxTypeEnum, t, 'cashbox').filter(
        (c) =>
            c.key !== CashboxTypeEnum.PAYPAL &&
            c.key !== CashboxTypeEnum.STRIPE &&
            c.key !== CashboxTypeEnum.BS,
    );

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: cashboxes.remove,
        saveRequest: recordId ? cashboxes.update : cashboxes.create,
        saveCallback: (savedRecord) => dispatch(addCashbox(savedRecord)),
        removeCallback: (removedRecord) => dispatch(removeCashbox(removedRecord.id)),
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ record.title || t('modalsHeader.cashbox') }
            initialValues={ serializeRecord(CreateCashboxDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched, setValues,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('tables.title') }
                        value={ values.title }
                        error={ touched.title && errors.title }
                        onChange={ handleChange('title') }
                        block={ true }
                        required={ true }
                    />
                    <Select
                        dataTestId="type"
                        selected={ values.type }
                        label={ t('tables.type') }
                        onChange={ ({ selected }) => {
                            setFieldValue('type', selected?.key);
                            setFieldValue('allLocations', false);
                        } }
                        options={ cashboxTypeOptions }
                        error={ touched.type && errors.type }
                        required={ true }
                        block={ true }
                        optionsListWidth="field"
                        hint={ values.type === CashboxTypeEnum.STRIPE && t('cahboxStripeHint') }
                    />

                    { values.type === CashboxTypeEnum.BANK ? (
                        <React.Fragment>
                            <Divider size="m" />
                            <LocationsPicker
                                title={ t('cashboxAllLocations') }
                                error={ !!errors.locationIds }
                                allLocations={ values.allLocations }
                                locationIds={ values.locationIds }
                                onChange={ (value) => setValues({ ...values, ...value }) }
                            />
                        </React.Fragment>
                    ) : (
                        <Select
                            dataTestId="location"
                            label={ t('address') }
                            options={ convertToOptions(locations) }
                            allowUnselect={ true }
                            error={ touched.locationIds && (errors.locationIds as string) }
                            required={ true }
                            selected={ values.locationIds?.[0] }
                            onChange={ ({ selected }) => {
                                setFieldValue('locationIds', [+selected?.key]);
                                setFieldValue('allLocations', false);
                            } }
                            block={ true }
                            optionsListWidth="field"
                        />
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
