import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateQuestroomResourceDto } from '@escapenavigator/types/dist/questroom/create-questroom-resource.dto';
import { QuestroomResourceRO } from '@escapenavigator/types/dist/questroom/questroom-resource.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { useApi } from 'src/providers/api/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    record?: QuestroomResourceRO;
};

export const ResourceModal: React.FC<Props> = ({
    record, close, t, cb,
}) => {
    const { questrooms: questroomsApi } = useApi();

    const questrooms = useAppSelector(
        selectAllQuestrooms({
            deleted: false,
            closed: false,
        }),
    );

    const recordId = record?.id;

    const {
        save,
        updating,
        remove,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: recordId && questroomsApi.removeResource,
        removeCallback: () => cb(),
        saveRequest: recordId ? questroomsApi.updateResource : questroomsApi.createResource,
        saveCallback: () => cb(),
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ recordId ? record.title : 'Add resource' }
            initialValues={ serializeRecord(CreateQuestroomResourceDto, record || {}) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('tables.title') }
                        value={ values.title }
                        error={ touched.title && errors.title }
                        onChange={ handleChange('title') }
                        block={ true }
                    />

                    <Input
                        dataTestId="amount"
                        label={ t('Количество') }
                        type="number"
                        value={ `${values.amount}` }
                        error={ touched.amount && errors.amount }
                        onChange={ handleChange('amount') }
                        block={ true }
                    />

                    <QuestroomsSelect
                        label={ t('Квесты') }
                        questrooms={ questrooms }
                        selected={ values.questroomsIds }
                        error={ touched.questroomsIds && (errors.questroomsIds as string) }
                        multiple={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'questroomsIds',
                                selectedMultiple.map((i) => i.key),
                            ) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
