import React from 'react';
import { CheckmarkCircleFillM } from '@alphakits/icons';
import {
    AmountInput,
    Button,
    Checkbox,
    CheckboxGroups,
    FlexColumns,
    Image,
    ImageUploader,
    Input,
    Padding,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CashboxTypeEnum } from '@escapenavigator/types';
import { CreateCertificateDto } from '@escapenavigator/types/dist/certificate/create-certificate.dto';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { ChipSelect } from 'src/components/chip-select';
import { ModalSection } from 'src/components/layout/modal-section';
import { NominalsBuilder } from 'src/components/nominals-builder';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    removeCertificate,
    selectAllCashboxes,
    selectAllLocations,
    selectAllQuestrooms,
    upsertCertificate,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    recordId?: number;
    close: () => void;
    t: TFunction;
};

const defaultCertificates = [
    {
        pdfLink:
            'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/certificate-tempaltes/cert1.webp',
        photo: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/certificate-tempaltes/cert1.webp',
    },
    {
        pdfLink:
            'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/certificate-tempaltes/cert2.webp',
        photo: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/certificate-tempaltes/cert2.webp',
    },
];

export const CertificateModal: React.FC<Props> = ({ recordId, close, t }) => {
    const dispatch = useAppDispatch();
    const { certificates, uploads } = useApi();

    const {
        profile: { currency },
    } = useCurrentUser();
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false })) || [];
    const cashboxes = useAppSelector(selectAllCashboxes);
    const locations = useAppSelector(selectAllLocations);

    const { record, loading, error } = useGetOne(recordId, certificates.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? certificates.update : certificates.create,
        removeRequest: certificates.remove,
        saveCallback: (saveRecord) => dispatch(upsertCertificate(saveRecord)),
        removeCallback: (removedRecord) => dispatch(removeCertificate(removedRecord.id)),
        close,
    });

    const defaultValues: Partial<CreateCertificateDto> = {
        deliveryInfo: t('Текст deliveryInfo'),
        pickupInfo: t('Текст pickupInfo'),
        deliveryPossibility: true,
        pickupPossibility: true,
        emailPossibility: true,
        photo: defaultCertificates[0].photo,
        pdfLink: defaultCertificates[0].pdfLink,
        onlinePaymentsCashbox: cashboxes.filter((c) => c.type === CashboxTypeEnum.STRIPE)[0]?.id,
        paypalCashbox: cashboxes.filter((c) => c.type === CashboxTypeEnum.PAYPAL)[0]?.id,
    };

    return (
        <RestForm
            recordId={ +recordId }
            title={ record?.id ? record.title : t('modalsHeader.certificate') }
            initialValues={ serializeRecord(
                CreateCertificateDto,
                record?.id ? record : defaultValues,
            ) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => {
                const templateType = defaultCertificates.some((c) => c.photo === values.photo)
                    ? 'our'
                    : 'own';

                return (
                    <React.Fragment>
                        <FlexColumns columns={ 1 } gr={ 32 }>
                            <ModalSection>
                                <Input
                                    dataTestId="title"
                                    label={ t('tables.title') }
                                    value={ values.title }
                                    onChange={ handleChange('title') }
                                    error={ touched.title && errors.title }
                                    required={ true }
                                    block={ true }
                                />

                                <AmountInput
                                    dataTestId="validity"
                                    label={ t('component.certificateValidity') }
                                    value={ values.validity }
                                    min="0"
                                    suffix={ t('days', { count: Number(values.validity) }) }
                                    onChange={ (e, { value }) => setFieldValue('validity', value) }
                                    error={ touched.validity && errors.validity }
                                    block={ true }
                                    required={ true }
                                />
                            </ModalSection>

                            <ModalSection title={ t('Варианты цен') }>
                                <NominalsBuilder
                                    currency={ currency }
                                    price={ values.nominals }
                                    error={ touched.nominals && errors.nominals?.toString() }
                                    onChange={ (price) => setFieldValue('nominals', price) }
                                    t={ t }
                                />

                                <Checkbox
                                    label={ t('Кастомный номинал') }
                                    checked={ values.allowFreeNominal }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('allowFreeNominal', checked) }
                                    block={ true }
                                />
                            </ModalSection>

                            <ModalSection title={ t('component.deliveryMethod') }>
                                <FlexColumns columns={ 1 } gr={ 8 }>
                                    <Checkbox
                                        dataTestId="emailPossibility"
                                        label={ t('component.emailPossibility') }
                                        checked={ values.emailPossibility }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('emailPossibility', checked) }
                                    />

                                    <Checkbox
                                        dataTestId="pickupPossibility"
                                        label={ t('component.pickupPossibility') }
                                        checked={ values.pickupPossibility }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('pickupPossibility', checked) }
                                    />

                                    <Checkbox
                                        dataTestId="deliveryPossibility"
                                        label={ t('component.deliveryPossibility') }
                                        checked={ values.deliveryPossibility }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('deliveryPossibility', checked) }
                                    />
                                </FlexColumns>

                                { values.deliveryPossibility && (
                                    <AmountInput
                                        dataTestId="deliveryPrice"
                                        label={ t('component.deliveryPrice') }
                                        value={ values.deliveryPrice }
                                        suffix={ currency }
                                        onChange={ (e, { value }) =>
                                            setFieldValue('deliveryPrice', value) }
                                        block={ true }
                                        type="decimal"
                                    />
                                ) }

                                { (values.pickupPossibility || values.deliveryPossibility) && (
                                    <AmountInput
                                        dataTestId="extraPrice"
                                        label={ t('tables.extraPrice') }
                                        value={ values.extraPrice }
                                        suffix={ currency }
                                        onChange={ (e, { value }) =>
                                            setFieldValue('extraPrice', value) }
                                        block={ true }
                                        type="decimal"
                                    />
                                ) }
                            </ModalSection>

                            <ModalSection title={ t('Доступность') }>
                                <Checkbox
                                    dataTestId="allQuestrooms"
                                    label={ t('component.forAllQuestrooms') }
                                    checked={ values.allQuestrooms }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('allQuestrooms', checked) }
                                />

                                { !values.allQuestrooms && (
                                    <React.Fragment>
                                        { touched.allQuestrooms && errors.questroomsIds && (
                                            <Typography.Text
                                                color="negative"
                                                view="title"
                                                weight="medium"
                                            >
                                                { errors.questroomsIds }
                                            </Typography.Text>
                                        ) }
                                        <Padding padding="0 0 0 20px">
                                            <CheckboxGroups
                                                parents={ locations }
                                                childs={ questrooms }
                                                selected={ values.questroomsIds }
                                                onChange={ (value) =>
                                                    setFieldValue('questroomsIds', value) }
                                            />
                                        </Padding>
                                    </React.Fragment>
                                ) }

                                <Checkbox
                                    dataTestId="awailableForWidgets"
                                    label={ t('component.onlineSaleForWidget') }
                                    checked={ values.awailableForWidgets }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('awailableForWidgets', checked) }
                                />
                                <Checkbox
                                    dataTestId="awailableForNavigator"
                                    label={ t('component.onlineSaleForAgregator') }
                                    checked={ values.awailableForNavigator }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('awailableForNavigator', checked) }
                                />
                            </ModalSection>

                            <ModalSection title={ t('Как выглядит упаковка') }>
                                <ChipSelect
                                    options={ [
                                        { key: 'our', content: t('Готовый') },
                                        { key: 'own', content: t('Собственная упаковка') },
                                    ] }
                                    value={ templateType }
                                    onChange={ (val) => {
                                        const photo =
                                            val === 'own' ? null : defaultCertificates[0].photo;
                                        const link =
                                            val === 'own' ? null : defaultCertificates[0].pdfLink;

                                        setFieldValue('photo', photo);
                                        setFieldValue('pdfLink', link);
                                    } }
                                />

                                { templateType === 'own' ? (
                                    <React.Fragment>
                                        <Input
                                            dataTestId="pdfLink"
                                            label={ t('component.downloadLink') }
                                            hint={ t('pdfLinkDescription') }
                                            value={ values.pdfLink }
                                            onChange={ handleChange('pdfLink') }
                                            error={ touched.pdfLink && errors.pdfLink }
                                            required={ true }
                                            block={ true }
                                        />

                                        <ImageUploader
                                            title={ `${t('Загрузите фото упаковки')} *` }
                                            subtitle={ t('component.photoUploaderSubtitle') }
                                            value={ values.photo ? [values.photo] : [] }
                                            onUpdate={ (value) => {
                                                setFieldValue('photo', value[0]);
                                            } }
                                            setMain={ (value) => setFieldValue('photo', value) }
                                            error={ touched.photo && errors.photo }
                                            uploadRequest={ uploads.uploadImage }
                                            type={ ImageTypeEnum.CERTIFICATE }
                                            multiple={ false }
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <FlexColumns columns={ 3 }>
                                            { defaultCertificates.map((cert) => (
                                                <Button
                                                    key={ cert.photo }
                                                    view="ghost"
                                                    onClick={ () => {
                                                        setFieldValue('photo', cert.photo);
                                                        setFieldValue('pdfLink', cert.pdfLink);
                                                    } }
                                                >
                                                    <Image
                                                        addon={
                                                            values.photo === cert.photo
                                                                ? {
                                                                    icon: (
                                                                        <CheckmarkCircleFillM />
                                                                    ),
                                                                    color: 'positive',
                                                                }
                                                                : undefined
                                                        }
                                                        size="auto"
                                                        src={ cert.photo }
                                                    />
                                                </Button>
                                            )) }
                                        </FlexColumns>
                                    </React.Fragment>
                                ) }
                            </ModalSection>

                            <ModalSection title={ t('typography.importantInfo') }>
                                <Textarea
                                    dataTestId="description"
                                    label={ t('component.specificationCertificate') }
                                    value={ values.description }
                                    hint={ t('component.minSymbol') }
                                    onChange={ handleChange('description') }
                                    block={ true }
                                    maxLength={ 500 }
                                    counter={ true }
                                />

                                { values.deliveryPossibility && (
                                    <Textarea
                                        dataTestId="deliveryInfo"
                                        label={ t('Информация по отправке курьером') }
                                        value={ values.deliveryInfo }
                                        hint={ t('component.minSymbol') }
                                        onChange={ handleChange('deliveryInfo') }
                                        error={ touched.deliveryInfo && errors.deliveryInfo }
                                        block={ true }
                                        maxLength={ 500 }
                                        counter={ true }
                                    />
                                ) }

                                { values.pickupPossibility && (
                                    <Textarea
                                        dataTestId="pickupInfo"
                                        label={ t('Как осуществляется самовывоз') }
                                        error={ touched.pickupInfo && errors.pickupInfo }
                                        value={ values.pickupInfo }
                                        hint={ t('component.minSymbol') }
                                        onChange={ handleChange('pickupInfo') }
                                        block={ true }
                                        maxLength={ 500 }
                                        counter={ true }
                                    />
                                ) }
                            </ModalSection>
                        </FlexColumns>
                    </React.Fragment>
                );
            } }
        </RestForm>
    );
};
