import React from 'react';
import { TrashS } from '@alphakits/icons';
import { Button, Flex } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { WebhookRO } from '@escapenavigator/types/dist/webhook/webhook.ro';

export const Columns = ({ remove }: { remove: (id: number) => void }): TableColumns<WebhookRO> => [
    {
        header: 'Url',
        accessor: 'url',
    },
    {
        header: '',
        accessor: 'id',
        width: '100px',
        row: {
            title: ({ row }) => (
                <Flex gap="md">
                    <Button
                        onClick={ (e) => {
                            e.stopPropagation();
                            remove(row.id);
                        } }
                        view="outlined"
                        size="xs"
                        leftAddons={ <TrashS /> }
                    />
                </Flex>
            ),
        },
    },
];
