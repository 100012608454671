import React from 'react';
import { Table } from '@alphakits/ui';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const BalancePayments: React.FC = () => {
    const { profiles } = useApi();

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder="Serach"
            language="en"
            promise={ profiles.queryProfileBalancePayments }
        />
    );
};
